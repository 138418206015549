import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import "bootstrap/dist/css/bootstrap.min.css";
import AppContext from "../app/AppContext";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  clipboardCheckBlue,
  clipboardCheckGray,
  folderBlue,
  folderGray,
  notificationBlue,
  notificationGray,
  profileBlue,
  profileGray
} from "../resources/Icons/icons.js";

const Menu = ({ setPage }) => {
  const context = useContext(AppContext);
  const [currentMenuSection, setCurrentMenuSection] = useState();
  const [hoveredSection, setHoveredSection] = useState(null);

  const setAppPage = (page) => {
    setPage(page);
    context.currentPage = page;
  };

  const pages = !context.DisableActions ? [
    {
      name: "Projects",
      paths: ["projects", "projectsDetails", "projectsAdd", "projectsAdded", "projectsList"],
      iconGray: folderGray,
      iconBlue: folderBlue
    },
    {
      name: "Tasks",
      paths: [
        "tasks", "task", "taskEditor", "taskReassign", "taskReassigned",
        "taskAdded", "task : regularTask", "taskAddedFail",
        "tasksEdit", "tasksEditSuccess", "taskEditorComments"
      ],
      iconGray: clipboardCheckGray,
      iconBlue: clipboardCheckBlue
    },
    {
      name: "Notifications",
      paths: ["notifications"],
      iconGray: notificationGray,
      iconBlue: notificationBlue
    },
    {
      name: "Profile",
      paths: ["profile"],
      iconGray: profileGray,
      iconBlue: profileBlue
    },
  ]
  :[   
    {
      name: "Tasks",
      paths: [
        "tasks", "task", "taskEditor", "taskReassign", "taskReassigned",
        "taskAdded", "task : regularTask", "taskAddedFail",
        "tasksEdit", "tasksEditSuccess", "taskEditorComments"
      ],
      iconGray: clipboardCheckGray,
      iconBlue: clipboardCheckBlue
    },   
    {
      name: "Profile",
      paths: ["profile"],
      iconGray: profileGray,
      iconBlue: profileBlue
    },
  ]
  ;

  const renderTooltip = (menuSection) => {
    const newPageKey = menuSection.name.toLowerCase();
    const newPage = menuSection.name == "Tasks" && context.appMode == "task" ? "taskEditor" : newPageKey;
    const cssMenuSelected = menuSection.name === currentMenuSection ? "menuButtonSelected" : "";
    const icon = menuSection.name === currentMenuSection || menuSection.name === hoveredSection ? menuSection.iconBlue : menuSection.iconGray;

    return (
      <React.Fragment key={menuSection.name}>
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 250 }}
          overlay={<Tooltip id={newPage}>{menuSection.name}</Tooltip>}
        >
          <button className={`btn btn-light ${context.DisableActions ? 'menuButtonHidden': ''} menuButton ${cssMenuSelected}`}
            onClick={() => setAppPage(newPage)}
            onMouseEnter={() => setHoveredSection(menuSection.name)}
            onMouseLeave={() => setHoveredSection(null)}
            style={{
              backgroundColor: "transparent",
              border: "none",
              outline: "none",
              boxShadow: "none"
            }}
          >
            <img src={icon} alt={menuSection.name} />
          </button>
        </OverlayTrigger>
      </React.Fragment>
    );
  };

  useEffect(() => {
    let found = false;
    for (const page of pages) {
      if (page.paths.includes(context.currentPage)) {
        setCurrentMenuSection(page.name);
        found = true;
        break;
      }
    }
    if (!found) setCurrentMenuSection("");
  }, [context.tokenReady, context.currentPage]);

  return (
    context.tokenReady && context.currentPage != "login" &&
    <div className="row fixed-top menuMargin whiteBackground">
      <div className="col-12">
        { pages.map((menuSection) => {
            return renderTooltip(menuSection);
          })
        }
      </div>
    </div>
  );
};

Menu.propTypes = {
  setPage: PropTypes.func.isRequired,
};

export default Menu;
