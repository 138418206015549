import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
class TaskMembers extends Component {
  state = {
    members: this.props.members || []
  };

  componentDidMount = () => {
    this.props.ownerId && this.moveOwnertoFront();
  }

  // owner (if there is one) should always be displayed first in blue, so move to front of member list
  moveOwnertoFront = () => {
    const owner = this.state.members.find(member => member.UserId === this.props.ownerId && !member.isGroup);
    if (owner) {

      let members = this.state.members.filter(member => member.UserId !== this.props.ownerId && !member.isGroup);
      let groups = this.state.members.filter(member => member.UserId !== this.props.ownerId && member.isGroup);
      let groupids = [];
      let groupsToBeAdded = groups.filter(group => {
        if (groupids.indexOf(group.GroupId) === -1) {
          groupids.push(group.GroupId);
          return group;
        }
      });

      this.setState({
        members: [
          owner,
          ...members,
          ...groupsToBeAdded
        ]
      })
    }
  }

  render() {
    let activeView = null;
    if (this.state.members) {
      if (this.state.members.length > 0) {
        let extraMembers = this.state.members.length - 4;
        activeView = (
          <div>
            {this.state.members.slice(0,4).map(member => {
              const isOwner = this.props.ownerId && this.props.ownerId === member.UserId && !member.isGroup;
              return(
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 250 }}
                  overlay={
                    <Tooltip id={"member " + member.User.Id} key={member.User.Id}>
                      {`${member.User.Name}${member.isGroup ? " [Group]" : ""}`}
                    </Tooltip>  
                  }
                  key={member.User.Id}
                > 
                  <span className={`userInitialsCompact appFont ${isOwner ? "userInitialsCompactOwner" : member.isGroup ? "userInitialsGroup" :""}`}key={member.User.Id}>
                    {member.User.Initials}
                  </span>
                </OverlayTrigger>
              )
            })}
            {/* If there are more than 4 members, display it at the end as +X */}
            {extraMembers > 0 &&
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 250 }}
                overlay={
                  <Tooltip id={"member " + extraMembers}>
                    <div>
                      {this.state.members.slice(4).map((member) => {
                        return(
                          <div key={member.User.Id}>
                            {`${member.User.Name}${member.isGroup ? " [Group]" : ""}`}
                          </div>
                        )
                      })}
                    </div>
                  </Tooltip>  
                }
              > 
                <span className={"userInitialsCompact appFont userInitialsExtra"}>
                  {`+${extraMembers}`}
                </span>
              </OverlayTrigger>
            }
          </div>
        );
      }
    } else {
      activeView = <div>-</div>;
    }

    return <div>{activeView}</div>;
  }
}

export default TaskMembers;
