//import "office-ui-fabric-react/dist/css/fabric.min.css";
import App from "./app/App";
//import { initializeIcons } from "office-ui-fabric-react/lib/Icons";
import * as React from "react";
import * as ReactDOM from "react-dom/client"; 
/* global Component, document, Office, module, require */

//initializeIcons();
console.log(`Office.js is now ready in`);
let isOfficeInitialized = false;

const title = "TRIYO Add-in";

const container = document.getElementById("container");
const root = ReactDOM.createRoot(container);

const render = Component => {
  root.render(
      <Component title={title} isOfficeInitialized={isOfficeInitialized} />
  );
};

/* Render application after Office initializes */
Office.initialize = () => {
  isOfficeInitialized = true;
  render(App);
};

if (import.meta.hot) { 
  import.meta.hot.accept("./app/App", () => {
    const App = require("./app/App").default;
    render(App);
  });
}
