import React, { Fragment, useContext } from "react";
import PropTypes from "prop-types";
import { OverlayTrigger, Popover, Dropdown, Tooltip } from "react-bootstrap";
import Moment from "react-moment";
import AppContext from "../../app/AppContext";
import downloadIcon from "../../resources/Icons/Download-20px-Blue-60.svg";
import arrowIconLightBlue from "../../resources/Icons/Icon-Arrow-Down-LightBlue-12x13px.svg";
import attachmentIconLightBlue from "../../resources/Icons/Icon-Attachment-LightBlue-12x13px.svg";
import TasksDynamicFieldsContent from "../dynamicFields/ContentViewer/TasksDynamicFieldsContent";
import TaskAuditReassignMembers from "./TaskAuditReassignMembers";
import { OBJECT_TYPES } from "../dynamicFields/utils/utils";
import DownloadButton from "../../resources/Icons/Download-12px-Blue-60.svg";
import ReadCountIcon from "../../resources/Icons/Icon-Eye-16px-Grey.svg";
import "./TaskAuditSingleComment.scss";
import { CommentActions } from "../../util/const-helpers";

const filesToDownload = (emailTask, documentList, index) => {
  try {
    if (!emailTask) {
      return documentList;
    } else if (emailTask && index === 0) {
      return documentList.filter((d, i) => i !== (documentList.length - 1));
    } else if (emailTask && index !== 0) {
      return documentList.filter((d, i) => i !== 0);
    }
  }
  catch (err) {
    console.log("ERROR : filesToDownload", err)
    return [];
  }
}

const trimCommentText = (commentText, emailTask, downloadEmailDocument, index, comment) => {
  try {
    if (comment.ActionId == CommentActions.Form_Submitted) {
      let formdata = JSON.parse(commentText);
      commentText = "The ‘" + formdata?.Label + "’ Form was Submitted";
    }
    else if (comment.ActionId == CommentActions.Form_Saved) {
      let formdata = JSON.parse(commentText);
      commentText = "A Draft of the ‘" + formdata?.Label + "’ Form was Saved";
    }
    const lines = commentText?.split("\n");
    const rawCommentText = commentText?.replace(/\s+/g, " ");
    let toTrim = false;

    // sometimes the comment is not too long, but has many lines
    if (lines?.length > 3) {
      commentText = lines?.slice(0, 4).join("\n");
      toTrim = true;
    } else if (rawCommentText?.length > 150) {
      toTrim = true;
    }

    if (toTrim && emailTask) {
      return (
        <React.Fragment>
          <span className="task-audit-comment-container">
            <span>{commentText?.substring(0, 150)}&nbsp;</span>
            <a className="truncation-button" onClick={() => downloadEmailDocument(index)}>
              ...
            </a>
          </span>
        </React.Fragment>
      );
    } else if (toTrim) {
      return <span>{commentText?.substring(0, 150)}&nbsp;...</span>;
    }

    return <span>{commentText}</span>;
  }
  catch (err) {
    return <span>Unable to trim this comment</span>
  }

};

const TaskAuditSingleComment = ({
  comment,
  downloadFiles,
  userInitials,
  startDate,
  actionType,
  userName,
  userEmail,
  commentClass,
  userComments,
  documentList,
  emailTask,
  downloadEmailDocument,
  taskRole,
  index,
  readCount,
  isLastComment
}) => {
  const context = useContext(AppContext);
  if (comment?.ActionId == CommentActions.Reassigned && comment.ReassignTaskMembers != null) {
    return (
      <div className="mt-2">
        <div className="d-flex commentLoadAnimation">
          <TaskAuditReassignMembers
            comment={comment}
            projectMembers={comment.ReassignTaskMembers?.ReassignUsers}
            userName={userName}
            userEmail={userEmail}
            taskRole={taskRole}
            actionType={actionType}
            commentText={userComments}
            downloadFiles={downloadFiles}
            documentList={documentList}
            index={index}
            emailTask={emailTask}
          />
      </div>
    </div>
    )
  }
  else {
    return (
      <div className="mt-2">
        <div className="d-flex commentLoadAnimation">
          {comment?.ActionId == CommentActions.Auto_Reminder ?
            <div className="userInitialsAudit userInitialsAuditTriyo pt-2">{userInitials}</div>
            :
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>{comment?.ExternalEmail ? comment?.ExternalEmail
                : (actionType === "Email Read" ? "Email Tracker" : userEmail)}</Tooltip>}
            >
              {comment?.ExternalEmail ?
                <div className="userDefaultPhoto">
                  <div className={`${!comment?.IsInternalOrg ? "user-create-photo-icon" : "user-create-photo-icon user-internal-org"}`}></div>
                </div>

                : <div className="userInitialsAudit pt-2">{userInitials}</div>}
            </OverlayTrigger>
          }
          <div className="taskAuditDetails flex-grow-1">
            <div className="d-flex align-items-start">
              <div className="taskAuditDateTimeText align-items-start">
                <Moment format="MM/DD/YYYY">{startDate}</Moment> <Moment format="h:mm a">{startDate}</Moment>
                &nbsp;
                {emailTask && (
                  <span className="taskAuditDownloadEmail">
                    via&nbsp;<a className="taskAuditDownloadViaEmail mt-2" onClick={() => downloadEmailDocument(index)}>Email</a>
                    <img src={DownloadButton} onClick={() => downloadEmailDocument(index)} />
                  </span>
                )}
                {comment?.ActionId == CommentActions.Auto_Reminder && (     //Auto Reminder
                  <span className="taskAuditDownloadEmail autoReminder">
                    via&nbsp;<a className="taskAuditDownloadViaEmail mt-2">Email</a>
                  </span>
                )}
              </div>
              <div className="taskAuditStatus align-items-end flex-shrink-1">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{actionType === "Reassigned" ? "Re-Assigned" : actionType}
                  </Tooltip>}
                >
                  <div className="taskAuditStatusContainer">
                    <div className="taskAuditStatusSpan">
                      {actionType === "Reassigned" ? "Re-Assigned" : actionType}
                    </div>
                    {readCount > 0 && (
                      <div className="taskAuditStatusEmailReads ml-1">
                        <img src={ReadCountIcon} />
                        <span className="emailReadCounter">{readCount}</span>
                      </div>
                    )}
                  </div>
                </OverlayTrigger>
              </div>
            </div>
            <div className="taskAuditComment">
              {comment?.ActionId == CommentActions.Auto_Reminder
                ?
                (comment.ExternalEmail ?
                  <span>{comment?.ExternalEmail > 30 ? `${comment?.ExternalEmail.slice(0, 30)}...` : comment?.ExternalEmail}</span>
                  :
                  <span>{userName?.length > 30 ? `${userName.slice(0, 30)}...` : userName} {comment?.ExternalEmail}</span>)
                :
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{comment?.ExternalEmail ? comment?.ExternalEmail
                    :
                    (actionType === "Email Read" ? "Email Tracker" : userEmail)}</Tooltip>}
                >{
                    comment?.ExternalEmail ?
                      <span>{comment?.ExternalEmail > 30 ? `${comment?.ExternalEmail.slice(0, 30)}...` : comment?.ExternalEmail}</span>
                      :
                      <span>{userName?.length > 30 ? `${userName?.slice(0, 30)}...` : userName} {comment?.ExternalEmail}</span>
                  }
                </OverlayTrigger>
              }
            </div>
            {comment.Group != null &&

              <div className="taskAuditComment taskAuditSubName">via  {" "}
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>
                    {comment?.Group?.Email}
                  </Tooltip>}
                >
                  <span className="taskAuditGroupName">
                    {comment?.Group?.Name}
                  </span>
                </OverlayTrigger>
              </div>}

            {comment.ExternalEmail && !comment?.IsInternalOrg ?
              <div className="taskAuditComment taskAuditSubName">External</div>
              :
              comment?.IsInternalOrg ? "" : <div className="taskAuditComment taskAuditSubName">{actionType === "Email Read" ? "" : taskRole}</div>}
            <span className={commentClass}>
              {trimCommentText(userComments, emailTask, downloadEmailDocument, index, comment)}
            </span>
            <div className="taskAuditDownloads mb-2 mt-2">
              {filesToDownload(emailTask, documentList, index).length > 0 && (
                <Fragment>
                  <OverlayTrigger
                    rootClose={true}
                    placement="top"
                    trigger="click"
                    overlay={
                      <Popover id="popover-contained">
                        <Popover.Content>
                          {
                            filesToDownload(emailTask, documentList, index)
                              .map((file, idx) => {
                                console.log(file);
                                console.log(idx);
                                return (
                                  <div key={idx} className="download-file" onClick={() => downloadFiles(file)}>
                                    <img
                                      src={downloadIcon}
                                      className="taskEditorDownloadIcon"
                                      alt="Download File"
                                      width="12px"
                                      height="12px"
                                    />
                                    <span className="download-file-name download-audit">
                                      {file.Name.length > 15
                                        ? `${file.Name.slice(0, 15)}...${file.Name.slice(
                                          file.Name.length - 5,
                                          file.Name.length
                                        )}`
                                        : file.Name}
                                    </span>
                                    {(emailTask ? (idx !== documentList.length - 2) : (idx !== documentList.length - 1)) && (
                                      <Dropdown.Divider className="dropdown-row-divider" />
                                    )}
                                  </div>
                                );
                              })}
                        </Popover.Content>
                      </Popover>
                    }
                  >
                    <div className="AuditTrailAttachmentContainer">
                      <span className="mt-2" style={{ color: "#1592E6", fontSize: "12px" }}>
                        <img src={attachmentIconLightBlue} className="taskEditorAttachmentIcon" alt="Attachment" />
                        {`${filesToDownload(emailTask, documentList, index).length} ${filesToDownload(emailTask, documentList, index).length > 1
                          ? "Attachments" : "Attachment"
                          }`}
                        <img src={arrowIconLightBlue} className="taskEditorArrowIcon" alt="Attachment" />
                      </span>
                    </div>
                  </OverlayTrigger>
                  {(filesToDownload(emailTask, documentList, index)).length > 1 && (
                    <div
                      className="downloadAll"
                      onClick={() => downloadFiles(
                        (filesToDownload(emailTask, documentList, index)))}
                    >
                      <img
                        src={downloadIcon}
                        className="taskEditorDownloadIcon"
                        alt="Download File"
                        width="12px"
                        height="12px"
                      />
                      <a style={{ color: "#1592E6", fontSize: "12px" }}>Download All</a>
                    </div>
                  )}
                </Fragment>
              )}
            </div>
            {comment && (
              <div>
                <TasksDynamicFieldsContent
                  projectTypeId={0}
                  task={context.currentTask}
                  taskTypeId={context.currentTask.workItemType}
                  objectId={comment.Id}
                  objectType={OBJECT_TYPES.taskComment}
                  sidePanelStyles={true}
                  comment={comment}
                />
              </div> )}
          </div>
        </div>
      </div>
    );
  }

};


TaskAuditSingleComment.propTypes = {
  comment: PropTypes.object,
  downloadFiles: PropTypes.func,
  userInitials: PropTypes.string,
  startDate: PropTypes.string,
  actionType: PropTypes.string,
  userName: PropTypes.string,
  userEmail: PropTypes.string,
  commentClass: PropTypes.string,
  userComments: PropTypes.string,
  documentList: PropTypes.array,
  emailTask: PropTypes.bool,
  downloadEmailDocument: PropTypes.func,
  taskRole: PropTypes.string,
  index: PropTypes.number,
  readCount: PropTypes.number,
  isLastComment: PropTypes.bool,
};

export default TaskAuditSingleComment;
