import React, { Fragment } from "react";
import { OverlayTrigger, Tooltip, Form } from "react-bootstrap";
import Dropdown from "../dropdown/dropdown";
import { Formik } from "formik";
import DatePicker from "react-datepicker";
import calendarIcon from "../../resources/Icons/Icon - calender.svg";
import { PulseLoader } from "react-spinners";

const ProjectAddForm = ({
  validate,
  errorRef,
  addProject,
  currUserId,
  availableUsers,
  getUserDetails,
  getGroupHover,
  selectUsers,
  setPage,
  scrollToError,
  dynamicFields,
  setDynamicFields,
  renderTaskDetailDynamicFields,
  setSelectedProjectTemplate,
  selectedProjectTemplate,
  defaultEndDate,
  isProjectTemplateLoading,
  projectTemplates,
  prioritiesAvailable,
  setDisableDynamicDetailErrors,
  disableDynamicDetailErrors,
}) => {
  const initialValue = () => {
    if (isProjectTemplateLoading) return {};
    const initialVal =  {
      projectName: "",
      projectTemplate: selectedProjectTemplate?.Id ?? "",
      projectDescription: "",
      projectStart: new Date(),
      projectEnd: defaultEndDate ?? false,
      projectSelectedUsers: [currUserId],
      projectFilter: "",
      inputClient: false,
      projectPriority: "",
  }
  return initialVal;
}

if (isProjectTemplateLoading) {
  return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh'}}>
          <PulseLoader color="#0d4166" loading={true} size={15} margin={5} />
      </div>
  );
}

  return (
    <Fragment>
      <Formik
        innerRef={errorRef}
        validate={validate}
        validateOnChange={false}
        onSubmit={(values, { setSubmitting }) => {
          addProject(values, dynamicFields);
          setSubmitting(false);
        }}
        initialValues={initialValue()}
      >
        {({
          setFieldValue,
          setFieldTouched,
          setFieldError,
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
          isSubmitting,
          isValidating,
          isValid
        }) => (
          <Form onSubmit={handleSubmit} className="ml-2 mr-2">
            <Form.Group>
              <Form.Label className="loginInput appFontSubHeading">
                {" "}
                Project <i className="red">*</i>{" "}
              </Form.Label>
              <Form.Control
                data-testid="projectName"
                name="projectName"
                type="text"
                className="form-control form-custom-control shadow-none"
                value={values.projectName}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.projectName && errors.projectName}
                placeholder="Enter project name"
                onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault(); }}
              />

              {touched.projectName && errors.projectName && (
                <div data-testid="sameProjectNameError" type="invalid" className="error-message">
                  {errors.projectName}
                </div>
              )}
            </Form.Group>

            <Form.Group className={touched.projectTemplate && errors.projectTemplate ? "mt-4" : ""} id="projectTemplate" data-testid="selected-project-template">
              <Form.Label className="loginInput appFontSubHeading">
                Project Template
              </Form.Label>
              <Dropdown
                values={projectTemplates.map(projectTemplate => {
                  return { id: projectTemplate.Id, name: projectTemplate.Name, days : projectTemplate.Days, description : projectTemplate.Description };
                })}
                dropdownButtonText={"Select an Option"}
                onSelect={selectedValue => {
                  setFieldValue("projectTemplate", selectedValue.id);
                  setFieldTouched("projectTemplate");
                  setSelectedProjectTemplate(selectedValue,setFieldValue);
                }}
                currentSelected={values.projectTemplate}
              />
              {errors.projectName && (
                <div type="invalid" className="error-message">
                  {errors.projectTemplate}
                </div>
              )}
            </Form.Group>
            <Form.Group className="mt-4">
              <Form.Label className="loginInput appFontSubHeading">
                Start Date <i className="red">*</i>
              </Form.Label>
              <DatePicker
                className={`form-control form-custom-control shadow-none ${touched.projectStart && errors.projectStart ? "is-invalid" : ""
                  }`}
                id="projectStart"
                name="projectStart"
                customInput={<input data-testid="projectStartDate" type="text" />}
                selected={values.projectStart}
                onChange={value => setFieldValue("projectStart", value)}
                onBlur={() => setFieldTouched("projectStart")}
                //minDate={new Date()}
                maxDate={
                  typeof values.projectEnd !== "undefined" ? values.projectEnd : new Date("December 31, 100 03:24:00")
                }
                autoComplete="off"
                popperPlacement="top-start"
              />
              <img className="calendar-icon" src={calendarIcon} alt="Calendar Icon" />
              {touched.projectStart && errors.projectStart && (
                <div type="invalid" className="error-message">
                  {errors.projectStart}
                </div>
              )}
            </Form.Group>

            <Form.Group className="mt-4">
              <Form.Label className="loginInput appFontSubHeading">
                End Date <i className="red">*</i>
              </Form.Label>
              <DatePicker
                className={`form-control form-custom-control shadow-none ${touched.projectEnd && errors.projectEnd ? "is-invalid" : ""
                  }`}
                customInput={<input data-testid="projectEndDate" type="text" />}
                id="projectEnd"
                name="projectEnd"
                selected={values.projectEnd}
                onChange={value => {
                  if (values.projectStart.getFullYear() == value.getFullYear() &&
                    values.projectStart.getMonth() == value.getMonth() &&
                    values.projectStart.getDate() == value.getDate()) {
                    value = new Date();
                    value.setUTCHours(23, 59, 59, 0);
                  }
                  setFieldValue("projectEnd", value);
                  if (!value) setFieldError("projectEnd", "This is a required field");
                  else if (values.projectStart > value) setFieldError("projectEnd", "Select a valid date");
                  else setFieldError("projectEnd", "");
                }}
                onBlur={() => setFieldTouched("projectEnd")}
                minDate={typeof values.projectStart !== "undefined" ? values.projectStart : new Date()}
                placeholderText="MM/DD/YYYY"
                autoComplete="off"
                popperPlacement="bottom-start"
                popperModifiers={[
                  {
                    behavior: "flip",
                    boundariesElement: "viewport",
                    enabled: false,
                    flipVariations: false,
                    flipVariationsByContent: false,
                    name: "flip",
                    order: 650,
                    padding: 5
                  }
                ]}
              />
              <img className="calendar-icon" src={calendarIcon} alt="Calendar Icon" />
              {touched.projectEnd && errors.projectEnd && (
                <div type="invalid" className="error-message">
                  {errors.projectEnd}
                </div>
              )}
            </Form.Group>

            <Form.Group className={touched.projectPriority && errors.projectPriority ? "mt-4" : ""} id="projectPriority">
              <Form.Label className="loginInput appFontSubHeading">
                Priority <i className="red">*</i>
              </Form.Label>
              <Dropdown
                values={prioritiesAvailable.map(p => {
                  return { id: p, name: p };
                })}
                dropdownButtonText={"Select an Option"}
                onSelect={selectedValue => {
                  setFieldValue("projectPriority", selectedValue.id);
                  setFieldTouched("projectPriority");
                }}
                currentSelected={values.projectPriority}
              />
              {values.projectPriority === "" && touched.projectPriority && (
                <div type="invalid" className="error-message">
                  {errors.projectPriority}
                </div>
              )}
            </Form.Group>

            <div id="projectDynamicFields">
              {renderTaskDetailDynamicFields({
                dynamicFields: dynamicFields,
                setDynamicFields,
                disableErrors: disableDynamicDetailErrors
              })}
            </div>

            <Form.Group data-testid="projectSelectedUsers" className="mt-4">
              <Form.Label className="loginInput appFontSubHeading">
                Select Members/Groups <i className="red">*</i>
              </Form.Label>
              <Form.Control
                data-testid="projectFilter"
                id="projectFilter"
                name="projectFilter"
                type="text"
                value={values.projectFilter}
                className="form-control form-custom-control shadow-none searchInput searchAdd appFont"
                placeholder="Search members/groups"
                onChange={handleChange}
                onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault(); }}
              />
              {touched.projectSelectedUsers && errors.projectSelectedUsers && (
                <Form.Label>
                  <div type="invalid" className="error-message-assign">
                    {errors.projectSelectedUsers}
                  </div>
                </Form.Label>
              )}

              <div className="reassign-list-group">
                <table>
                  <tbody className="projectAddUserList mt-3">
                    {availableUsers
                      .filter(
                        f =>
                          f.Name.toLowerCase().includes(values.projectFilter.toLowerCase()) ||
                          values.projectFilter === ""
                      )
                      .map(availableUser => {
                        return availableUser.Active &&
                          <tr className="projectAddUserItem" key={availableUser.Id}>
                            <td className="w-1 pl-3 initials-container">
                              <span className={`userInitialsCompact ${availableUser.Id == currUserId ? "userInitialsCompactOwner" : ""}`}>
                                {availableUser.Initials}
                              </span>
                            </td>
                            <td className="w-8 pl-2 name-container">
                              <OverlayTrigger
                                placement="auto-start"
                                delay={{ show: 250, hide: 50 }}
                                overlay={
                                  <Tooltip id="tooltip-1" className="userListTitle" placement="auto-start">
                                    {getUserDetails(availableUser)}
                                  </Tooltip>
                                }
                              >
                                <span className="projectUserListTitle pull-left">
                                  {getUserDetails(availableUser)}
                                </span>
                              </OverlayTrigger>
                              <div className={availableUser.isGroup ? "group-hover" : ""} title={availableUser.isGroup ? getGroupHover(availableUser) : ''} />
                            </td>
                            <td className="w-1 pr-2 check-container">
                              <input
                                type="checkbox"
                                className="projectUserCheckbox"
                                checked={values.projectSelectedUsers.includes(availableUser.Id)}
                                onChange={() => {
                                  selectUsers(
                                    availableUser.Id,
                                    values.projectSelectedUsers,
                                    setFieldValue,
                                    setFieldTouched
                                  );
                                }}
                              ></input>
                            </td>
                          </tr>
                      })}
                  </tbody>
                </table>
              </div>
            </Form.Group>

            <Form.Group className="mt-4 mb-1">
              <Form.Label className="loginInput appFontSubHeading">Description</Form.Label>
              <Form.Control
                className="form-control form-custom-control shadow-none projectDescription"
                as="textarea"
                data-testid="projectDescription"
                id="projectDescription"
                name="projectDescription"
                rows="5"
                value={values.projectDescription}
                onChange={handleChange}
                placeholder="Enter description"
              ></Form.Control>
            </Form.Group>

            <div className="pb-5" />
            <div className="pb-4" />
            <Form.Group>
              <div className="fixed-bottom pt-4 pb-3 whiteBackground projectAddButtons">
                <div className="row">
                  <div className="col-12 taskModeButtonContainer">
                    <button
                      className="loginButton btn-primary blueButtonOutline shadow-none taskModeButton"
                      onClick={() => setPage("projectsList")}
                    >
                      Cancel
                    </button>
                    <button
                      data-testid="projectAddFormSubmitButton"
                      className="loginButton btn-primary blueButton shadow-none taskModeButton"
                      type="submit"
                      disabled={isSubmitting}
                      onClick={() => {
                        setDisableDynamicDetailErrors(false)
                      }}
                    >
                      Create Project
                    </button>
                  </div>
                </div>
              </div>
            </Form.Group>
            {/* Formik validation occurs automatically both on form change and on submit. Formik form events used to ensure the scroll event
              is called only once on submit; during Formik validation when the form is registered as invalid.*/}
            {isValidating && isSubmitting && !isValid ? scrollToError(touched, errors) : null}
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};

export default ProjectAddForm;

