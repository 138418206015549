import React, { Component } from "react";
import { OverlayTrigger, Tooltip, Form } from "react-bootstrap";
import { Formik } from "formik";
import { validFileTypes, contentTypeMapper, isImageFile, validFileTypesString } from '../../util/file-helpers.js';
import "bootstrap/dist/css/bootstrap.min.css";
import ValidationMessages from "../ValidationMessages";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AppContext from "../../app/AppContext";
import ApiService from "../../services/ApiService";
import FileTransferService from "../../services/FileTransferService";
import OfficeSharedService from "../../services/OfficeSharedService";
import OfficeWordService from "../../services/OfficeWordService";
import { calendarIcon, attachmentIcon, removeAttachment, emailIcon, plusIcon, lockGrayIcon } from "../../resources/Icons/icons.js";
import { trackPromise } from "react-promise-tracker";
import LoadingOverlay from "../loading-overlay/loading-overlay";
import Dropdown from "../dropdown/dropdown";
import OfficePowerpointService from "../../services/OfficePowerpointService";
import TaskAssignMembers from "./TaskAssignMembers";
import DynamicFieldsFormWrapper from "../../components/dynamicFields/Form/DynamicFieldsFormWrapper";
import { OBJECT_TYPES, renderTaskDetailDynamicFields, saveDynamicFieldsDetails, isDynamicFieldsValid } from "../dynamicFields/utils/utils.js";
import moment from 'moment-business-days';
import { workDays } from "../../helpers/HelperMethods.js";
import { setEndDate } from "../../helpers/mailLinkUtils.js";
import "../../css/globals.scss";
import "./TaskAdd.scss";

class TaskAdd extends Component {
  state = {
    taskAdd: null,
    page: "addTask",
    taskName: "",
    taskComments: "",
    taskDue: new Date(),
    taskTypeDue: moment()
    .add(7, "d")
    .toDate(),
    endDateChange: false,
    taskPriority: "Medium",
    taskType: "",
    taskTypeId: 0,
    taskSelectedUsers: [],
    valMsgs: [],
    prioritiesAvailable: [],
    //taskSelectedSlides: 0,
    //taskSelectedSlidesData: null,
    officeOnline: false,
    //selectedSlidesString: "",
    projectInfo: null,
    projectList: [],
    currProject: {},
    currentProjectId: 0,
    previousProjectId: 0,
    assignedMembers: [],
    assignedOtherDepMembers :[],
    assignedOwner: {},
    filteredAssignedMembers: [],
    filteredOtherDepMembers: [],
    slidesUnavailable: [],
    code: "",
    selectedText: "",
    selectedMembersNone: [],
    selectedMembersView: [],
    selectedMembersEdit: [],
    selectedMembersApprove: [],
    selectedFiles: [],
    selectedSlides: "",
    selectedSlidesList: "",
    selectedFile: null,
    commentNote: "",
    noteAction: 1,
    hide: false,
    submitting: false,
    replacementCodes: [],
    selectionErrors: "",
    showUseCase3: false,
    statusForUseCase3: 1, //Assigned
    values: [],
    taskTypes: [],
    //customRange: false,
    selectedEmail: null,
    outlookEmails: [],
    multipleEmailsSelected: false,
    emailId: null,
    emailConversationId: null,
    emailThread: "",
    loading: true,
    displayAlert: "",
    isError: false,
    htmlEmailBody: "",
    inlineFiles: [],
    disableDynamicDetailErrors: true,
  };

  static contextType = AppContext;

  displayError = () => {
    setTimeout(() => {
      this.setState({ isError: false });
    }, 5000)
  }

  //get email bodies using EWS request
  getOutlookEmailBodies = async () => {
    try {
      const emails = [];
      const selectedItems = this.context.currEmails;
      for (const selectedItem of selectedItems) {
        if (selectedItem.itemType === "Message") {
          const itemId = selectedItem.itemId;
          const ewsRequest = `
          <soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/"
                          xmlns:t="http://schemas.microsoft.com/exchange/services/2006/types">
            <soap:Header>
              <t:RequestServerVersion Version="Exchange2016" />
            </soap:Header>
            <soap:Body>
              <GetItem xmlns="http://schemas.microsoft.com/exchange/services/2006/messages">
                <ItemShape>
                  <t:BaseShape>Default</t:BaseShape>
                </ItemShape>
                <ItemIds>
                  <t:ItemId Id="${itemId}" />
                </ItemIds>
              </GetItem>
            </soap:Body>
          </soap:Envelope>`;

          const emailBodyAndAttachments = await new Promise((resolve, reject) => {
            this.context.currEmail.makeEwsRequestAsync(ewsRequest, result => {
              if (result.status === Office.AsyncResultStatus.Succeeded) {
                const parser = new DOMParser();
                const xmlDoc = parser.parseFromString(result.value, "text/xml");
                const bodyElement = xmlDoc.getElementsByTagName("t:Body")[0];
                const bodyText = bodyElement ? bodyElement.textContent : "";
                const email = { htmlEmailBody: bodyText };
                const fileAttachmentNodes = xmlDoc.getElementsByTagName("t:FileAttachment");
                if (fileAttachmentNodes.length > 0) {
                  const attachments = [];
                  for (let i = 0; i < fileAttachmentNodes.length; i++) {
                    const id = fileAttachmentNodes[i].getElementsByTagName("t:AttachmentId")[0].getAttribute("Id");
                    const name = fileAttachmentNodes[i].getElementsByTagName("t:Name")[0].textContent;
                    const isInline = fileAttachmentNodes[i].getElementsByTagName("t:IsInline")[0].textContent;
                    attachments.push({ id: id, name: name, isInline: isInline });
                  }
                  email.attachments = attachments;
                }
                resolve(email);
              } else {
                reject(result.error);
              }
            });
          });

          const email = {
            emailId: itemId,
            emailConversationId: selectedItem.conversationId,
            emailThread: selectedItem.subject,
            htmlEmailBody: emailBodyAndAttachments.htmlEmailBody,
            attachments: emailBodyAndAttachments.attachments
          };
          emails.push(email);
        }
      }
      console.log("outlookEmailBodies -->");
      return emails;
    } catch (error) {
      console.log(error);
    }
  };

  getOutlookEmailAttachments = async (email) => {
    let acceptedFiles = [];
    let inlineFiles = [];
    let loadedFilesCount = 0;

    const processAttachment = async (attachment, isInline) => {
      let splitAttached = attachment.name.split(".");
      let extension = "." + splitAttached[splitAttached.length - 1];
      loadedFilesCount++;

      try {
        const attachmentContent = await new Promise((resolve, reject) => {
          this.context.currEmail.item.getAttachmentContentAsync(
            attachment.id,
            (result) => {
              if (result.status === Office.AsyncResultStatus.Succeeded) {
                resolve(result.value);
              } else {
                reject(result.error);
              }
            }
          );
        });

        const fileObject = {
          name: attachment.name,
          FileName: attachment.name,
          FileContentType: contentTypeMapper[extension],
          Base64String:
            attachmentContent.format ===
            Office.MailboxEnums.AttachmentContentFormat.Base64
              ? attachmentContent.content
              : null,
          ContentId: isInline ? attachment.id : null,
        };

        if (isInline) {
          inlineFiles.push(fileObject);
        } else {
          acceptedFiles.push(fileObject);
        }
      } catch (error) {
        console.error("Error processing attachment:", error.message);
      }
    };

    for (const attachment of email.attachments) {
        if (this.isAcceptedFileType("." + attachment.name.split(".").pop())) {
            await processAttachment(attachment, false);
        } else if (attachment.isInline === "true") {
            await processAttachment(attachment, true);
        }
    }

    return { acceptedFiles, inlineFiles };
  };

  //get blank task object
  getTaskAdd = async () => {
    await ApiService.getTaskModel(this.context.currentProjectId, this.context)
      .then(async (result) => {
        if (result.status === 200) {

          this.setState({
            taskAdd: result.data,
            taskDue: new Date(result.data.Due),
            prioritiesAvailable: result.data.PrioritiesAvailable,
            replacementCodes: result.data.WordReplacementCodes
          });

          if (this.context.officeAppType === "Outlook" && !this.props.isRegularTask) {
            if (this.context.currEmails?.length > 1) {
                this.setState({ multipleEmailsSelected: true });
                const emails = await this.getOutlookEmailBodies();
                for (let index = 0; index < emails.length; index++) {
                    const email = emails[index];
                    const outlookEmail = {
                        emailId: email.emailId,
                        emailConversationId: email.emailConversationId,
                        emailThread: email.emailThread,
                        htmlEmailBody: encodeURIComponent(JSON.stringify(email.htmlEmailBody))
                    };
                    if (index === 0 && email?.attachments?.length) {
                        const attachments = await this.getOutlookEmailAttachments(email);
                        outlookEmail.emailAttachments = attachments.acceptedFiles;
                        this.linkContentIdToImageAttachment(email.htmlEmailBody, attachments.inlineFiles);
                        outlookEmail.emailInlineAttachments = this.state.inlineFiles;
                    }
                    else {
                      outlookEmail.emailAttachments = "";
                      outlookEmail.emailInlineAttachments = "";
                    }
                    this.setState((prevState) => ({
                        outlookEmails: [...prevState.outlookEmails, outlookEmail],
                    }));
                    console.log(this.state.outlookEmails);
                }
            }
            else {
              this.context.currEmail.item.body.getAsync('text', (bodyEmail) => {
                const cleanContent = this.removeHtmlTagsFromEmail(bodyEmail.value);
                this.setState({ commentNote: cleanContent });
              });

              this.context.currEmail.item.body.getAsync('html', (bodyEmail) => {
                this.setState({ htmlEmailBody: bodyEmail.value });
              });

              if (this.context.currEmail?.item?.conversationId) {
                await ApiService.getEmailTask(this.context.currEmail?.item?.conversationId, this.context)
                  .then(async (emailtask) => {
                    if (emailtask.status === 200) {
                      //const emailThreadExists = result.data.Tasks.find(t => t.EmailThread == this.context.currEmail?.item?.subject); // Comparing against subject
                      const emailThreadExists = emailtask.data;  // Comparing against conversationid
                      if (!emailThreadExists) {
                        this.setState({
                          selectedEmail: this.context.currEmail,
                          taskName: this.context.currEmail?.item?.subject,
                          emailId: this.context.currEmail?.item?.itemId,
                          emailConversationId: this.context.currEmail?.item?.conversationId,
                          emailThread: this.context.currEmail?.item?.subject,
                        });
                        this.onGetEmailAttachments(this.context.currEmail.item);
                      } else {
                        this.setState({ commentNote: '' });
                      }
                    }
                  });
              }
            }
          }
        } else {
          console.log("API [GetTaskModel] error status: " + result.status);
        }
      })
      .catch(e => {
        console.log("API [GetTaskModel] error ->");
        console.log(e);
        this.props.setPage("500 error");
      })
  };

  //get blank project object
  getProjectAdd = async () => {
    await ApiService.getProjectWithDep(this.context.currentProjectId, this.context).then(
      async (result) => {
        if (result.status === 200) {
          let noPermissionList = [];
          let getCurrProject = [];

          result.data.Members.forEach(member => {
            if (member.isGroup) {
              noPermissionList.push(member.GroupId + 9999999);
            } else {
              noPermissionList.push(member.UserId);
            }
          });

          this.setState({
            // selectedMembersApprove: addOwnerToApprovers,
            selectedMembersNone: noPermissionList,
            projectInfo: result.data
          });
          getCurrProject = this.state.projectList.filter(p => p.Name === this.state.projectInfo?.Name);
          console.log("Projects data", result.data);
          this.getAllAssignedMembers(result.data.Members, result.data.DepMembers);
          this.setState({ currProject: getCurrProject[0] });
          this.setState({ loading: false });
        } else {
          console.log("API [GetProject] error status: " + result.status);
          this.setState({ loading: false });
        }
      },
      (error) => {
        console.log("API [GetProject] error ->");
        console.log(error);
        this.props.setPage("500 error");
        this.setState({ loading: false });
      }
    )
  };

  projectFilter = (project, today) => {
    if ((new Date(project.End).getTime()) > (today.getTime())) {
      let isMember = false;
      project.Members.forEach((member) => {
        if (member.UserId === this.context.currUser.Id) isMember = true;
      })

      return isMember;
    }

    return false;
  }

  //Get list of all user projects
  getUserProjects = async () => {
    await ApiService.getUserProjects(this.context)
      .then(result => {
        if (result.status === 200) {
          let today = new Date();
          let allowedProjects = result.data.filter(project => this.projectFilter(project, today));
          this.setState({
            projectList: allowedProjects
          }, () => {
            this.setTaskDefaultValues();
          });
        } else {
          console.log("API [GetUserProject] error status: " + result.status);
        }
        this.setState({ loading: false });
      })
      .catch(e => {
        console.log("API [GetUserProject] error ->");
        console.log(e);
        this.props.setPage("500 error");
      })
  };

  //Get TaskTypes
  getTaskTypes = async () => {
    await ApiService.getTaskTypes(this.context)
      .then(result => {
        if (result.status === 200) {
          this.setState({
            taskTypes: result.data
          });
          let data = result.data;
          ApiService.getDefaultEmailTaskType(this.context)
          .then((result) => {
            if (result.status === 200) {
              if (result.data) {
                let defaultTaskTypeArray = data.filter(t => t.Id == result.data);
                if (defaultTaskTypeArray && defaultTaskTypeArray?.length > 0) {
                  let duration = defaultTaskTypeArray[0]?.Days;
                  let isBusinessDays = defaultTaskTypeArray[0]?.IsBusinessDays;
                  let today = new Date();
                  if (isBusinessDays) {
                    today = momentBusiness().businessAdd(duration)._d;
                  } else {
                    today.setDate(today.getDate() + duration);
                  }
                  let pEnd = duration !== 0 ? today : "";
                  this.setState({
                    taskTypeId: defaultTaskTypeArray[0]?.Id,
                    taskTypeDue: pEnd,
                    taskDue: pEnd
                  });
                }
              }
            }
          })
          .catch((e) => {
            console.log(e);
          });
        } else {
          console.log("API [GetTaskTypes] error status: " + result.status);
        }
      })
      .catch(e => {
        console.log("API [GetTaskTypes] error ->");
        console.log(e);
        this.props.setPage("500 error");
      })
  };

  setTaskDefaultValues = () => {
    const unassignedProject = this.state.projectList.find(p => p.Name === "Unassigned");
    if (unassignedProject) {
      this.setState({ 
        projectInfo: unassignedProject,
        currentProjectId: unassignedProject.Id
      });
      this.context.currentProjectId = unassignedProject.Id;
    }
    if (!this.state.taskName) {
      this.setState({ 
        taskName: "New Email Task"
      });
    }
  }
  getDefaultTaskType = async () => {
    let taskTypeId = 0;
    let taskTypeName = "";
    let defaultEndDate = new Date();
    await ApiService.getDefaultTaskType(this.context)
      .then(result => {
        if (result.status === 200) {
          console.log("Default Task Type", result.data);
          taskTypeId = result.data?.Id ?? 0;
          taskTypeName = result.data?.Type ?? "";
          const duration = result.data?.Days ?? 0;
          const isBusinessDays = result.data?.IsBusinessDays ?? false;
          const today = new Date();
          console.log("Today Date!!", today, duration, isBusinessDays);
          defaultEndDate = isBusinessDays ? momentBusiness().businessAdd(duration)._d : today.setDate(today.getDate() + duration);
          console.log("Default Task Type!!", taskTypeId, taskTypeName, defaultEndDate);
        } else {
          console.error("API [GetDefaultTaskType] error status: " + result.status);
        }
      })
      this.setState({
        taskTypeId: taskTypeId,
        taskType: taskTypeName,
        taskTypeDue: defaultEndDate,
      });
    };

  getAllAssignedMembers = (currMembers, otherMembers) => {
    let projectMembers = [];
    let filterMembersNames = [];
    let otherProjectMembers = [];
    let otherFilterMembersNames = [];

    currMembers.forEach(function (member) {
      filterMembersNames.push(member.User.Name);
      projectMembers.push(member.User);
    });

    otherMembers.forEach(function (member) {
      otherFilterMembersNames.push(member.Name);
      otherProjectMembers.push(member);
    });

    this.setState({
      assignedMembers: projectMembers,
      filteredAssignedMembers: filterMembersNames,
      assignedOtherDepMembers: otherProjectMembers,
      filteredOtherDepMembers: otherFilterMembersNames,
    });
  }

  componentDidMount() {
    if (this.context.officeAppType === "Word") {
      this.getTaskAdd();
      this.getProjectAdd();
    } else if (this.context.officeAppType === "PowerPoint") {
      this.getUnavailableSlides();
      this.getTaskAdd();
      this.getProjectAdd();
    } else if (this.context.officeAppType === "Outlook") {
      this.getTaskAdd();
      this.getTaskTypes();
      this.getDefaultTaskType();
      if (this.context.currentProjectId) this.setState({ currentProjectId: this.context.currentProjectId });
      if (this.props.isFromProject || this.context.previousPage == "projects" || this.context.previousPage == "projectsAdded" || this.context.previousPage == "projectsDetails") {
        this.getProjectAdd();
      }
      this.getUserProjects();
    }
    moment.updateLocale('us', workDays);
    this.setState({
      assignedOwner: this.context.currUser
    });
  }

  setProjectId(id) {
    if (OfficeSharedService.getAppType(Office.context) == "Outlook") {
      this.context.currentProjectId = id;
      this.getProjectAdd();
    }
  }

  cancelTaskCreation = async () => {
    if (this.props.isFromProject) {
      await this.props.setPage("projects");
    } else {
      await this.props.setPage("tasks");
    }
  }

  // moves user to appropriate array depending on assigned permission
  onSelectedUserRole = (id, event) => {
    let selectedMembersNone = this.state.selectedMembersNone;
    const indexNone = selectedMembersNone.indexOf(id);
    if (indexNone > -1) {
      selectedMembersNone.splice(indexNone, 1);
    }

    let selectedMembersView = this.state.selectedMembersView;
    const indexView = selectedMembersView.indexOf(id);
    if (indexView > -1) {
      selectedMembersView.splice(indexView, 1);
    }

    let selectedMembersEdit = this.state.selectedMembersEdit;
    const indexEdit = selectedMembersEdit.indexOf(id);
    if (indexEdit > -1) {
      selectedMembersEdit.splice(indexEdit, 1);
    }

    let selectedMembersApprove = this.state.selectedMembersApprove;
    const indexApprove = selectedMembersApprove.indexOf(id);
    if (indexApprove > -1) {
      selectedMembersApprove.splice(indexApprove, 1);
    }


    let role = event.target.value;

    if (role == "View") {
      selectedMembersView.push(id);
    } else if (role == "Edit") {
      selectedMembersEdit.push(id);
    } else if (role == "Approve") {
      selectedMembersApprove.push(id);
    } else {
      selectedMembersNone.push(id);
    }

    this.setState({
      selectedMembersNone: selectedMembersNone,
      selectedMembersView: selectedMembersView,
      selectedMembersEdit: selectedMembersEdit,
      selectedMembersApprove: selectedMembersApprove,
    });
  };

  removeHtmlTagsFromEmail = (content) => {
    if (!content) return;

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with an empty string.
    return content.replace(/(<([^>]+)>)/ig, '');
  }

  showError = (message) => {
    this.setState({
      displayAlert: message,
      isError: true
    });
    this.displayError();
  }

  checkInvalidFile = async (fileObj) => {
    if (fileObj) {
      if (!validFileTypesString.includes(fileObj.name.toLowerCase().split(".").pop())) {
        this.showError(`File format must be ${validFileTypesString}`);
        return false;
      } else if (fileObj.size > 52428800) {
        this.showError("File size exceeds 50MB.");
        return false;
      } else if (fileObj.size > 10485760 && isImageFile(fileObj)) {
        this.showError("File size exceeds 10MB.");
        return false;
      }
      this.setState({ isError: false });
      return true;
    } else {
      this.showError("File cannot be uploaded.");
      return false;
    }
  };

  isAcceptedFileType = (extension) => {
    const acceptedAttachments = new Set(validFileTypes);
    return acceptedAttachments.has(extension);
  }

  onGetEmailAttachments = (emailItem) => {
    if (emailItem?.attachments?.length > 0) {
      let acceptedFiles = [];
      let inlineFiles = [];
      let loadedFilesCount = 0;

      emailItem.attachments.forEach((attachment) => {
        let splitAttached = attachment.name.split(".");
        let extension = "." + splitAttached[splitAttached.length - 1];
        loadedFilesCount++;

        if (this.isAcceptedFileType(extension)) {
          emailItem.getAttachmentContentAsync(attachment.id, (result) => {
            if (result.value.format === Office.MailboxEnums.AttachmentContentFormat.Base64) { //base64
              acceptedFiles = [...acceptedFiles, { name: attachment.name, FileName: attachment.name, FileContentType: contentTypeMapper[extension], Base64String: result.value.content }];
            }

            if (loadedFilesCount === emailItem.attachments.length) {
              //update state on last file when async function updates file list
              this.setState({ selectedFiles: acceptedFiles, inlineFiles: inlineFiles });
            }
          });
        } else if (attachment.isInline) { //if the file is png or html
          emailItem.getAttachmentContentAsync(attachment.id, (result) => {
            if (result.value.format === Office.MailboxEnums.AttachmentContentFormat.Base64) { //base64
              inlineFiles = [...inlineFiles, { name: attachment.name, FileName: attachment.name, FileContentType: contentTypeMapper[extension], Base64String: result.value.content, ContentId: attachment.id }];
            }

            if (loadedFilesCount === emailItem.attachments.length) {
              //update state on last file when async function updates file list
              this.setState({ selectedFiles: acceptedFiles, inlineFiles: inlineFiles });
            }
          });
        }
      });
    }
  };

  // Formik validation handler
  validate = (values, dynamicFields, setDynamicFields) => {
    let errors = {};
    let selectedMemberIds = [
      ...this.state.selectedMembersView,
      ...this.state.selectedMembersEdit,
      ...this.state.selectedMembersApprove
    ];
    let selectedGroups = selectedMemberIds.filter(f => f > 9999999);

    if (this.context.officeAppType == "PowerPoint") {
      if (this.state.selectionErrors.length > 0) {
        errors.selectedSlides = this.state.selectionErrors;
      } else if (this.state.selectedSlides.length == 0) {
        errors.selectedSlides = "Range is empty, please make a selection.";
      }
    }
    if (!values.taskName) {
      errors.taskName = "This is a required field";
    }
    else if(values.taskName.trim().length === 0){
      errors.taskName = "Task Name cannot contain only spaces";
    }
    if (!values.projectName) {
      errors.projectName = "This is a required field";
    }
    if (!this.state.prioritiesAvailable.includes(values.taskPriority)) {
      errors.taskPriority = "This is a required field";
    }
    if (this.context.officeAppType == "Outlook") {
      if (this.state.taskTypeId === 0) {
        errors.taskType = "This is a required field";
      }
    }

    if (!values.taskDue) {
      errors.taskDue = "This is a required field";
    }

    let ownerHasPermission = false;
    if (this.state.selectedMembersEdit.includes(this.context.currUser.Id) || this.state.selectedMembersApprove.includes(this.context.currUser.Id) || this.state.selectedMembersView.includes(this.context.currUser.Id)) {
      ownerHasPermission = true;
    }
    if (this.state.selectedMembersEdit.length === 0 && ownerHasPermission) {
      errors.selectedMembers = "Please select at least one edit role";
    }
    if (selectedGroups.length > 1) {
      let selectedGroupMembers = [];
      this.state.projectInfo.Members.forEach(member => {
        if (member.isGroup && selectedGroups.includes(member.GroupId + 9999999)) {
          member.Group.Members.forEach(groupMember => {
            if (!selectedMemberIds.includes(groupMember.UserId)) {
              if (selectedGroupMembers.includes(groupMember.UserId)) {
                errors.selectedMembers = "Please remove selected groups with conflicting members";
                return errors;
              }
              selectedGroupMembers.push(groupMember.UserId);
            }
          });
        }
      });
    }

    if (!isDynamicFieldsValid(dynamicFields)) {
      setDynamicFields(dynamicFields.map(field => ({ ...field, touched: true })));
      errors.dynamicFields = "Dynamic Fields are Invalid";
    }
    //if conflicting groups, errors.selectedMembers = "Please remove selected groups with conflicting members";
    return errors;
  };

  isUseCase3 = (values, dynamicFields) => {
    let ownerIsEditor = false;
    let memberApproverCount = this.state.selectedMembersApprove.length;
    let memberIsEditor = false;

    let MembersEdit = this.state.selectedMembersEdit;
    if (this.state.selectedMembersEdit.length === 0 && !(this.state.selectedMembersEdit.includes(this.context.currUser.Id) || this.state.selectedMembersApprove.includes(this.context.currUser.Id) || this.state.selectedMembersView.includes(this.context.currUser.Id))) {
      MembersEdit.push(this.context.currUser.Id);
      this.setState({
        selectedMembersEdit: MembersEdit
      });
    }

    if (this.state.selectedMembersEdit.length === 1 && this.state.selectedMembersEdit.includes(this.context.currUser.Id))
      ownerIsEditor = true;
    else if (this.state.selectedMembersEdit.length === 1 && !this.state.selectedMembersEdit.includes(this.context.currUser.Id))
      memberIsEditor = true;

    if (ownerIsEditor && memberApproverCount > 0 && !memberIsEditor) {
      this.setState({
        showUseCase3: true,
        values: values
      });
    } else {
      this.onAddTask(values, 0, dynamicFields);
    }
  }

  addMembersInForm = (permission, id) => {
    let addedMembers = [];
    if (permission === "editor") {
      addedMembers = [...this.state.selectedMembersEdit];
    } else if (permission === "viewer") {
      addedMembers = [...this.state.selectedMembersView];
    } else if (permission === "approver") {
      addedMembers = [...this.state.selectedMembersApprove];
    }

    const indexMembers = addedMembers.indexOf(id);
    if (indexMembers > -1) {
      addedMembers.splice(indexMembers, 1);
    }
    addedMembers.push(id);

    if (permission === "editor") {
      this.setState({
        selectedMembersEdit: addedMembers
      });
    } else if (permission === "viewer") {
      this.setState({
        selectedMembersView: addedMembers
      });
    } else if (permission === "approver") {
      this.setState({
        selectedMembersApprove: addedMembers
      });
    }
  };

  removeMembersInForm = (permission, id) => {
    let updatedMembers = [];
    if (permission === "editor") {
      updatedMembers = [...this.state.selectedMembersEdit];
    } else if (permission === "viewer") {
      updatedMembers = [...this.state.selectedMembersView];
    } else if (permission === "approver") {
      updatedMembers = [...this.state.selectedMembersApprove];
    }

    updatedMembers = updatedMembers.filter(member => member !== id);

    if (permission === "editor") {
      this.setState({
        selectedMembersEdit: updatedMembers
      });
    } else if (permission === "viewer") {
      this.setState({
        selectedMembersView: updatedMembers
      });
    } else if (permission === "approver") {
      this.setState({
        selectedMembersApprove: updatedMembers
      });
    }
  };

  addNewUsersToProject = async projectId => {
    const intMemberIds = [
      ...this.state.selectedMembersEdit,
      ...this.state.selectedMembersView,
      ...this.state.selectedMembersApprove
    ];
    const allProjMembers = this.state.projectInfo?.Members;
    const addedNewMembers = intMemberIds.filter(i => !allProjMembers.find(member => member.UserId === i));

    if (addedNewMembers.length > 0) {
      let stringMemberIds = addedNewMembers.toString();

      await ApiService.addMembersToProject(projectId, stringMemberIds, this.context)
        .then(res => {
          if (res.status == 200) {
            console.log("Members successfully added in task added");
          }
        })
        .catch(error => {
          console.log("Error adding other Member =>");
          console.log(error);
        });
    }
  };

  // Submission handler, uses Office API to get the selected document text and executes callback
  onAddTask = (values, flag, dynamicFields) => {
    let tempStatus = 1;
    if (flag == 1) {
      tempStatus = 11;
    }
    else {
      tempStatus = 1;
    }

    if (this.context.officeAppType === "Word") {
      this.setState({
        taskName: values.taskName,
        taskComments: values.commentNote,
        taskDue: values.taskDue,
        taskPriority: values.taskPriority,
        commentNote: values.commentNote?.substr(0, 2000),
        submitting: true,
        statusForUseCase3: tempStatus
      }, () =>
        OfficeWordService.getDocumentSelectionText("", this.onAddTaskWordCheckSelection));//.then(result => {}).catch(err => {}), 'task-add-area'

    }

    if (this.context.officeAppType === "PowerPoint") {
      console.log("Submitting PowerPoint ->");
      this.setState({
        taskName: values.taskName,
        taskComments: values.commentNote,
        taskDue: values.taskDue,
        taskPriority: values.taskPriority,
        commentNote: values.commentNote?.substr(0, 2000),
        submitting: true,
        statusForUseCase3: tempStatus
      }, () =>
        this.apiAddTaskPowerpoint());
    }
    if (this.context.officeAppType === "Outlook") {
      console.log("Submitting Outlook ->");
      this.setState({
        taskName: values.taskName,
        taskComments: values.commentNote,
        taskDue: values.taskDue,
        taskPriority: values.taskPriority,
        taskTypeId: values.taskType,
        commentNote: values.commentNote?.substr(0, 2000),
        submitting: true,
        statusForUseCase3: tempStatus
      },
        () => this.apiAddStandardTask(dynamicFields)
      );
    }
  };

  // uses Office API to convert retrieved document selection to usable xml code then executes callback
  onAddTaskWordCheckSelection = selection => {
    if (selection.length > 5) {
      let code = OfficeSharedService.getCode(18);

      this.setState({
        code: code
      });

      OfficeWordService.getDocumentSelectionOoxml(code, this.state.replacementCodes, this.onAddTaskWordSelectionReady);
    } else {
      let valMsg = this.state.valMsgs;
      valMsg = [];
      valMsg.push("No text has been selected.");
      this.setState({ valMsgs: valMsg, submitting: false });
    }
  };

  // saves document selection to state, retreives current document, then executes callback
  onAddTaskWordSelectionReady = selection => {
    if (selection == null) {
      console.log("Locked Content Selected");
      let valMsg = this.state.valMsgs;
      valMsg = [];
      valMsg.push("Please do not select locked content.");
      this.setState({ valMsgs: valMsg, submitting: false });
      return;
    }

    this.setState({
      selectedText: selection
    });

    if (this.context.newDoc) {
      trackPromise(
        FileTransferService.uploadDocumentFile(Office.context, this.getFileName)
          .then(result => { })
          .catch(err => { }),
        "task-add-area"
      );
    } else {
      trackPromise(
        FileTransferService.uploadDocumentFile(Office.context, this.apiAddTaskWord)
          .then(result => { })
          .catch(err => { }),
        "task-add-area"
      );
    }
  };

  getFileName = docdataSlices => {
    let classScope = this;
    if (Office.context.document != null) {
      Office.context.document.getFilePropertiesAsync(function (asyncResultProperties) {
        let fileName = "";
        if (asyncResultProperties.value.url != "") {
          fileName = asyncResultProperties.value.url;
        }
        classScope.uploadNewProjectDocument(docdataSlices, fileName);
      });
    }
  };

  uploadNewProjectDocument = (docdataSlices, fileName) => {
    var classScope = this;
    let file = FileTransferService.getFileFromSlices(docdataSlices, this.context.officeAppType);
    const fd = new FormData();
    fd.append("file", file);
    fd.append("projectId", this.context.currentProjectId);
    fd.append("fileType", this.context.officeAppTypeDoc);
    fd.append("fileName", fileName);

    trackPromise(
      ApiService.addProjectFile(fd, this.context)
        .then(result => {
          if (result.status === 200) {
            classScope.context.currentProjectDocumentId = result.data.Id;
            classScope.context.currentProjectFileLock = true;
            OfficeWordService.setFilePropertyProject(classScope.context.currentProjectId);
            OfficeWordService.setFilePropertyDocument(result.data.Id);
            classScope.apiAddTaskWord(docdataSlices);
          } else {
            console.log("API [AddProjectFile] error status: " + result.status);
          }
        })
        .catch(e => {
          console.log("API [AddProjectFile] error ->");
          console.log(e);
          this.props.setAppPage("500 error");
        }),
      "task-add-area"
    );
  };

  // sends document selection, document file, and other data to api and creates task
  apiAddTaskWord = docdataSlices => {
    let file = FileTransferService.getFileFromSlices(docdataSlices, this.context.officeAppType);
    const fd = new FormData();
    fd.append("file", file);
    fd.append("projectId", this.context.currentProjectId);
    fd.append("documentId", this.context.currentProjectDocumentId);
    fd.append("code", this.state.code);
    fd.append("selectedText", this.state.selectedText);
    fd.append("taskName", this.state.taskName);
    fd.append("selectedSlides", this.state.selectedSlides);
    fd.append("taskDescription", this.state.taskComments);
    fd.append("taskDue", this.state.taskDue);
    fd.append("priority", this.state.taskPriority);
    fd.append("taskStatus", this.state.statusForUseCase3)

    // formats arrays as string to send as form data
    let selectedMembersNone = "";
    this.state.selectedMembersNone.forEach(element => (selectedMembersNone += element + ","));
    let selectedMembersView = "";
    this.state.selectedMembersView.forEach(element => (selectedMembersView += element + ","));
    let selectedMembersEdit = "";
    this.state.selectedMembersEdit.forEach(element => (selectedMembersEdit += element + ","));
    let selectedMembersApprove = "";
    this.state.selectedMembersApprove.forEach(element => (selectedMembersApprove += element + ","));

    fd.append("selectedMembersNone", selectedMembersNone);
    fd.append("selectedMembersView", selectedMembersView);
    fd.append("selectedMembersEdit", selectedMembersEdit);
    fd.append("selectedMembersApprove", selectedMembersApprove);

    this.state.selectedFiles.forEach(selectedFile => {
      fd.append("file", selectedFile);
    });
    fd.append("note", this.state.commentNote);

    trackPromise(
      ApiService.addTaskWord(fd, this.context)
        .then(result => {
          if (result.status === 200) {
            this.setState({ selectedFiles: [], commentNote: "", submitting: false });
            this.props.setPage("taskAdded");
          } else {
            console.log("API [AddTask] error status: " + result.status);
          }
        })
        .catch(e => {
          console.log("API [AddTask] error ->");
          console.log(e);
          this.props.setPage("500 error");
        }),
      "task-add-area"
    );
  };

  onAddTaskPowerpoint = () => {
    //this.checkSlideSelection();
    let valMsg = [];
    if (this.state.taskName.length === 0) {
      valMsg.push("Please enter a task name.");
    }
    /*if (this.state.taskSelectedSlides === 0) {
      valMsg.push("At least one slide has to be selected.");
    }*/
    console.log("selectSlides - > " + this.state.selectSlides);
    let taskAdd = this.state.taskAdd;
    taskAdd.Name = this.state.taskName;
    taskAdd.Description = this.state.taskComments;
    taskAdd.Due = this.state.taskDue;
    taskAdd.SelectedUsers = this.state.taskSelectedUsers;
    taskAdd.Priority = this.state.taskPriority;
    taskAdd.ProjectId = this.context.currentProjectId;
    taskAdd.DocumentId = this.context.currentProjectDocumentId;
    taskAdd.UserSelectedSlides = this.state.selectedSlides;
    taskAdd.SelectedMembersNone = "";
    taskAdd.SelectedMembersView = "";
    taskAdd.SelectedMembersEdit = "";
    taskAdd.SelectedMembersApprove = "";

    this.state.selectedMembersNone.forEach(element => (taskAdd.SelectedMembersNone += element + ","));
    this.state.selectedMembersView.forEach(element => (taskAdd.SelectedMembersView += element + ","));
    this.state.selectedMembersEdit.forEach(element => (taskAdd.SelectedMembersEdit += element + ","));
    this.state.selectedMembersApprove.forEach(element => (taskAdd.SelectedMembersApprove += element + ","));

    taskAdd.SelectedMembersNone = taskAdd.SelectedMembersNone.replace("null", "");
    taskAdd.SelectedMembersView = taskAdd.SelectedMembersView.replace("null", "");
    taskAdd.SelectedMembersEdit = taskAdd.SelectedMembersEdit.replace("null", "");
    taskAdd.SelectedMembersApprove = taskAdd.SelectedMembersApprove.replace("null", "");

    /*let selectedSlides = [];
    let selectedSlidesString = "";
    this.state.taskSelectedSlidesData.slides.forEach(element => selectedSlides.push(element.index));
    this.state.taskSelectedSlidesData.slides.forEach(element => (selectedSlidesString += element.index + "|"));
    taskAdd.SelectedSlides = selectedSlides;*/
    this.setState({ taskAdd: taskAdd /*, selectedSlidesString: selectedSlidesString*/ });
    console.log("onAddTaskPowerpoint ->");
    console.log(this.state.taskAdd);
    if (valMsg.length === 0) {
      this.setState({ valMsgs: [] });
      this.apiAddTaskPowerpoint();
    } else {
      this.setState({ valMsgs: valMsg });
      console.log(valMsg);
    }
  };

  linkContentIdToImageAttachment = (htmlEmailBody, inlineAttachments) => {
    const imageTags = htmlEmailBody.split('<img ');
    for (let i = 1; i < imageTags.length; i++) {
      let dataString = imageTags[i];

      let sourceString = dataString.slice(dataString.search("src=\"cid:") + 9);
      let contentID = sourceString.slice(0, sourceString.search("\""));

      let attachmentIndex;
      let imageNamePosition = dataString.search("alt=\"");

      if (imageNamePosition !== -1) {
        let imageNameString = dataString.slice(dataString.search("alt=\"") + 5);
        let imageName = imageNameString.slice(0, imageNameString.search("\""));

        attachmentIndex = inlineAttachments.findIndex(attachment => attachment.FileName === imageName);
      }
      else {
        attachmentIndex = inlineAttachments.findIndex(attachment => contentID.includes(attachment.FileName));
      }

      try {
        inlineAttachments[attachmentIndex].ContentId = contentID;
      } catch { }
    }

    this.setState({ inlineFiles: inlineAttachments })

  }

  onSubmitFormData = async () => {
    const newFormData = new FormData();
    newFormData.append("projectId", this.context.currentProjectId);
    newFormData.append("taskName", this.state.taskName);
    newFormData.append("taskDescription", this.state.taskComments);
    newFormData.append("taskDue", this.state.taskDue);
    newFormData.append("priority", this.state.taskPriority);
    newFormData.append("taskStatus", this.state.statusForUseCase3);

    if (this.context.officeAppType === "Outlook") {
      newFormData.append("taskTypeId", this.state.taskTypeId);      
      if (!this.state.multipleEmailsSelected) {
        this.linkContentIdToImageAttachment(this.state.htmlEmailBody, this.state.inlineFiles);
        newFormData.append("outlookEmails", JSON.stringify([{
          emailId: this.state.emailId,
          emailConversationId: this.state.emailConversationId,
          emailThread: this.state.emailThread,
          emailAttachments: this.state.selectedFiles,
          htmlEmailBody: encodeURIComponent(JSON.stringify(this.state.htmlEmailBody)),
          emailInlineAttachments: this.state.inlineFiles
        }]));
      } else {
        newFormData.append("outlookEmails", JSON.stringify(this.state.outlookEmails));
        console.log(this.state.outlookEmails);
      }
    }

    if (this.context.officeAppType === "PowerPoint") {
      newFormData.append("documentId", this.context.currentProjectDocumentId);
      newFormData.append("selectedText", this.state.selectedText);
      newFormData.append("selectedSlides", this.state.selectedSlidesList);
      newFormData.append("userSelectedSlides", this.state.selectedSlides);
    }

    // formats arrays as string to send as form data
    let selectedMembersNone = "";
    this.state.selectedMembersNone.forEach(element => (selectedMembersNone += element + ","));
    let selectedMembersView = "";
    this.state.selectedMembersView.forEach(element => (selectedMembersView += element + ","));
    let selectedMembersEdit = "";
    this.state.selectedMembersEdit.forEach(element => (selectedMembersEdit += element + ","));
    let selectedMembersApprove = "";
    this.state.selectedMembersApprove.forEach(element => (selectedMembersApprove += element + ","));

    newFormData.append("selectedMembersNone", selectedMembersNone);
    newFormData.append("selectedMembersView", selectedMembersView);
    newFormData.append("selectedMembersEdit", selectedMembersEdit);
    newFormData.append("selectedMembersApprove", selectedMembersApprove);

    return newFormData;
  }

  apiAddTaskPowerpoint = async () => {
    const fd = this.onSubmitFormData();

    await ApiService.addTaskPowerpoint(fd, this.context)
      .then(async(result) => {
        if (result.status === 200) {
          this.setState({ taskAdd: result.data });
          await FileTransferService.uploadDocumentFile(Office.context, this.apiAddTaskDocument);
        } else {
          console.log("API [AddTask] error status: " + result.status);
        }
      })
      .catch(e => {
        console.log("API [AddTask] error ->");
        console.log(e);
        this.props.setPage("500 error");
      });
  };

  apiAddStandardTask = async(dynamicFields) => {
    const fd = await this.onSubmitFormData();
    console.log("apiAddStandardTask -> fd");
    console.log(fd);
    await ApiService.addStandardTask(fd, this.context)
      .then( async(result) => {
        if (result.status === 200) {
          await saveDynamicFieldsDetails(dynamicFields, result.data.Id, this.context)
          this.props.setPage("taskAdded");
        } else {
          console.log("API [AddTaskStandard] error status: " + result.status);
        }
      })
      .catch((error) => {
        console.log("API [AddTaskStandard] error ->");
        console.log(error);
        this.props.setPage("500 error");
      })
      .finally(() => this.setState({ selectedFiles: [], commentNote: "", submitting: false, htmlEmailBody: "", outlookEmails: [], inlineFiles: [] }));
  };

  apiAddTaskDocument = async(docdataSlices) => {
    let classScope = this;
    let file = await FileTransferService.getFileFromSlices(docdataSlices, this.context.officeAppType);

    const fd = new FormData();
    fd.append("file", file);
    fd.append("projectId", this.context.currentProjectId);
    fd.append("taskId", this.state.taskAdd.Id);
    fd.append("documentId", this.context.currentProjectDocumentId);
    //fd.append("selectedSlides", this.state.selectedSlidesString);
    //fd.append("selectSlides", this.state.selectSlides);
    //fd.append("selectedSlides", this.state.selectedSlides);

    let mSel = "";
    this.state.taskAdd.MultiplePptTasks.forEach(element => (mSel += element + ","));

    fd.append("multipleSelections", mSel);

    this.state.selectedFiles.forEach(selectedFile => {
      fd.append("file", selectedFile);
    });
    fd.append("note", this.state.commentNote);

    await ApiService.addTaskPowerpointDocuments(fd, this.context)
      .then(result => {
        if (result.status === 200) {
          classScope.context.currentProjectFileLock = true;
          classScope.props.setPage("taskAdded");
        } else {
          console.log("API [AddTaskDocument] error status: " + result.status);
        }
      })
      .catch(e => {
        console.log("API [AddTaskDocument] error ->");
        console.log(e);
        this.props.setPage("500 error");
      });
  };

  getUnavailableSlides = async () => {
    let data = { DocumentId: this.context.currentProjectDocumentId };

    await ApiService.getSlidesSelected(data, this.context)
      .then(result => {
        if (result.status === 200) {
          this.setState({ slidesUnavailable: result.data });
        } else {
          console.log("API [AddProject] error status: " + result.status);
        }
      })
      .catch(e => {
        console.log("API [AddProject] error ->");
        console.log(e);
      });
};

  /*getSelectedSlides = () => {
    let res = this.formatSelectedSlides(this.state.taskSelectedSlidesData.slides.map(slide => slide.index));
    if (res.length > 0){
      res = "#" + res;
    }
    return res;
  };*/

  getUnavailableSlidesList = slides => {
    return this.formatSelectedSlides(slides);
  };

  // Task description event handler
  onChangeCommentNote = e => {
    this.setState({
      commentNote: e.target.value
    });
  };

  // Task description attachment handler
  onFileChange = event => {
    this.setState({ selectedFile: event.target.files[0] });
  };

  // Email  handler
  onEmailChange = event => {
    console.log("onEmailChange:")
    console.log(event)
    //this.setState({ selectedEmail: event.target });
  };

  onBrowseFilesLoaded = (convertedFiles) => {
    let files = this.state.selectedFiles;

    if (files.length === 0) {
      convertedFiles.forEach(file => {
        if (!this.checkInvalidFile(file)) {
          return;
        }
        files.push(file);
      });
    } else {
      let fileFound = false;
      convertedFiles.forEach(file => {
        for (let i = 0; i < files.length; i++) {
          if (files[i].name === file.name) {
            fileFound = true;
          }
        }
        if (!fileFound) {
          if (!this.checkInvalidFile(file)) {
            return;
          }
          files.push(file);
        }
      });
    }

    this.setState({ selectedFiles: files });
  };

  filesToBase64 = (files, onBrowseFilesLoaded) => {
    let convertedFiles = [];
    let loadedFilesCount = 0;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();
      reader.onloadend = () => {
        // Use a regex to remove data url part
        const base64String = reader.result.replace('data:', '').replace(/^.+,/, '');
        convertedFiles = [...convertedFiles, { name: file.name, FileName: file.name, FileContentType: file.type, Base64String: base64String }];
        loadedFilesCount++;

        if (loadedFilesCount === files.length) {
          onBrowseFilesLoaded(convertedFiles);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  browseFile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (this.context.officeAppType === "Word" || this.context.officeAppType === "PowerPoint") {
      let files = this.state.selectedFiles;
      let newSelectedFiles = Array.from(e.target.files);
      e.target.value = null;
      if (files.length === 0) {
        files = newSelectedFiles;
      } else {
        let fileFound = false;
        newSelectedFiles.forEach(file => {
          for (let matchingFile of files) {
            if (matchingFile.name === file.name) {
              fileFound = true;
              break;
            }
          }
          if (!fileFound) {
            files.push(file);
          }
        });
      }

      this.setState({
        selectedFiles: files
      });
    }
    else {
      let newSelectedFiles = Array.from(e.target.files);
      e.target.value = null;
      this.filesToBase64(newSelectedFiles, this.onBrowseFilesLoaded);
    }

  };

  deleteBrowseFile = (e, name) => {
    e.preventDefault();
    e.stopPropagation();
    let files = this.state.selectedFiles;
    let idx = files.findIndex(file => file.name === name);
    files.splice(idx, 1);
    this.setState({
      selectedFiles: files
    });
  };

  // hides component body when loading overlay is displayed (security purposes, prevents inspect element to remove overlay)
  hideContent = hidden => {
    if (this.state.hide != hidden) {
      this.setState({ hide: hidden });
    }
  };

  // auto scroll to invalid form fields
  scrollToError = (touched, errors) => {
    if (errors.selectedSlides && this.context.officeAppType === "PowerPoint") {
      document.querySelector('input[name$="selectedSlides"]').scrollIntoViewIfNeeded();
    } else if (touched.projectName && errors.projectName) {
      document.querySelector('#projName').scrollIntoViewIfNeeded();
    } else if (touched.taskName && errors.taskName) {
      document.querySelector('input[name$="taskName"]').scrollIntoViewIfNeeded();
    } else if (touched.taskDue && errors.taskDue) {
      document.querySelector('input[name$="taskDue"]').scrollIntoViewIfNeeded();
    } else if (touched.taskPriority && errors.taskPriority) {
      document.getElementById("taskPriority").scrollIntoViewIfNeeded();
    } else if (touched.selectedMembers && errors.selectedMembers) {
      document.getElementById("commentNote").scrollIntoViewIfNeeded();
    }

    return null;
  };

  formatSelectedSlides = slidesList => {
    let selectedSlides = slidesList;
    selectedSlides = Array.from(new Set(selectedSlides));
    //selectedSlides.sort();
    selectedSlides.sort(function (a, b) {
      return a - b;
    });
    let res = "";
    let slideRangeStart = 0;

    for (let i = 0; i < selectedSlides.length; i++) {
      const isLastSlide = i == selectedSlides.length - 1;
      const nextSlide = selectedSlides[i + (isLastSlide ? 0 : 1)];

      if (nextSlide - selectedSlides[i] == 1) {
        if (slideRangeStart == 0) {
          slideRangeStart = selectedSlides[i];
        } else {
          continue;
        }
      } else {
        if (slideRangeStart > 0) {
          res += `${slideRangeStart}-${selectedSlides[i]}${isLastSlide ? "" : ", "}`;
          slideRangeStart = 0;
        } else {
          res += `${selectedSlides[i]}${isLastSlide ? "" : ", "}`;
        }
      }
    }
    return res;
  };

  updateSelectSlidesValue(evt, setFieldTouched) {
    let val = evt.target.value.replace(/\s+/g, "");
    if (val[val.length - 1] == ",") {
      val = val.substring(0, val.length - 1);
    }
    if (val.length == 0) {
      this.setState({
        selectionErrors: "Range is empty, please make a selection.",
        selectedSlides: "",
        selectedSlidesList: ""
      });
      return;
    }

    if (!val.match(/^[0-9 ,-]+$/)) {
      this.setState({
        selectionErrors: "Range is invalid, please make a proper selection.",
        selectedSlides: "",
        selectedSlidesList: ""
      });
      return;
    }
    OfficePowerpointService.getSlideCount().then(
      result => {
        let selectedSlides = this.validateSlideSelection(val, result);
        console.log("List:", selectedSlides.slidesList, "Error:", selectedSlides.errorMessage);
        this.setState({
          selectionErrors: selectedSlides.errorMessage,
          selectedSlides: selectedSlides.slidesList,
          selectedSlidesList: selectedSlides.selectedSlidesList
        });
        setFieldTouched("selectedSlides");
        return;
      },
      error => {
        console.log("Error: updateSelectSlidesValue");
      }
    );
  }

  validateSlideSelection = (slideStr, slideCount) => {
    let selectedSlides = slideStr.split(",");
    let errorMessage = "";
    let slidesList = [];

    if (selectedSlides.length == 0) {
      errorMessage = "Range is empty, please make a selection.";
      return { errorMessage: errorMessage, slidesList: "", selectedSlidesList: "" };
    }

    for (let slide of selectedSlides) {
      if (slide.includes("-")) {
        let rangePair = slide.split("-");
        if (rangePair.length == 2) {
          let slideStart = parseInt(rangePair[0]);
          let slideEnd = parseInt(rangePair[1]);

          if (
            !isNaN(slideStart) &&
            !isNaN(slideEnd) &&
            slideStart > 0 &&
            slideEnd > 0 &&
            slideStart <= slideEnd &&
            slideEnd <= slideCount
          ) {
            for (let i = slideStart; i <= slideEnd; i++) {
              if (!this.state.slidesUnavailable.includes(i)) {
                slidesList.push(i);
              } else {
                errorMessage = "Range contains assigned slides.";
                return { errorMessage: errorMessage, slidesList: "", selectedSlidesList: "" };
              }
            }
          } else {
            errorMessage = "Range is invalid, please make a proper selection.";
            return { errorMessage: errorMessage, slidesList: "", selectedSlidesList: "" };
          }
        } else {
          errorMessage = "Range is invalid, please make a proper selection.";
          return { errorMessage: errorMessage, slidesList: "", selectedSlidesList: "" };
        }
      } else {
        let slideNumber = parseInt(slide);
        if (!isNaN(slideNumber) && slideNumber > 0 && slideNumber <= slideCount) {
          if (!this.state.slidesUnavailable.includes(slideNumber)) {
            slidesList.push(slideNumber);
          } else {
            errorMessage = "Range contains assigned slides.";
            return { errorMessage: errorMessage, slidesList: "", selectedSlidesList: "" };
          }
        } else {
          errorMessage = "Range is invalid, please make a proper selection.";
          return { errorMessage: errorMessage, slidesList: "", selectedSlidesList: "" };
        }
      }
    }

    let slideListCommaSeparated = Array.from(new Set(slidesList))
      .sort()
      .join();

    return {
      errorMessage: "",
      slidesList: this.formatSelectedSlides(slidesList),
      selectedSlidesList: slideListCommaSeparated
    };
  };

  getGroupHover = group => {
    let members = group.Members;
    let result = "";

    members.forEach((member, index) => {
      if (index === members.length - 1) {
        result += `${member.User.Name} (${member.User.Email}) - ${member.User.Role.Name}`;
      } else {
        result += `${member.User.Name} (${member.User.Email}) - ${member.User.Role.Name} \n`;
      }
    });

    return result;
  };

  getPermissionRow = (member, setFieldTouched) => {
    const isGroup = member.isGroup;
    let memberInfo = null;
    let rowText = "";

    if (isGroup) {
      memberInfo = member.Group;
      if (memberInfo.Id < 9999999) {
        memberInfo.Id += 9999999;
      }
      rowText = `${memberInfo.Name} - ${memberInfo.Size}`;
    } else {
      memberInfo = member.User;
      rowText = `${memberInfo.Name} (${memberInfo.Role.Name})`;
    }

    return (
      <tr className="projectAddUserItem" key={memberInfo.Id}>
        <td className="w-1 pl-3">
          <span className="userInitials">{memberInfo.Initials}</span>
        </td>
        <td className="w-9 pl-2">
          <div className="taskAddInline taskAddName">
            <OverlayTrigger
              placement="auto-start"
              delay={{ show: 250, hide: 50 }}
              overlay={
                <Tooltip id="tooltip-1" className="customTooltip" placement="auto-start">
                  {rowText}
                </Tooltip>
              }
            >
              <span className="taskUserListTitle overflowEllipsesReassign pull-left w-10">{rowText}</span>
            </OverlayTrigger>
            {isGroup ? <div className={"group-hover"} title={this.getGroupHover(memberInfo)} /> : null}
          </div>
          <div className="taskAddInline taskAddPermission">
            <select
              className="taskAddSelect w-10 overflowEllipsesReassign permission-dropdown-inner ml-0 form-custom-control-2"
              onChange={event => {
                this.onSelectedUserRole(memberInfo.Id, event);
                setFieldTouched("selectedMembers");
              }}
            >
              <option value="">Select Permission</option>
              <option value="View">View</option>
              <option value="Edit">Edit</option>
              <option value="Approve">Approve</option>
            </select>
          </div>
        </td>
      </tr>
    );
  };

  getCurrUser = async () => {
    await ApiService.getCurrentUser(this.context)
      .then(result => {
        if (result.status === 200) {
          this.context.currUser = result.data.User
        } else {
          console.log("API [GetCurrentUser] error status: " + result.status);
        }
      }).catch(e => {
        console.log("API [GetCurrentUser] error ->");
        console.log(e);
        this.props.setAppPage("500 error");
      });
  };

  // uses the currently selected template to determine duration of project template
  setEndDate = (setFieldValue, currTaskType) =>{
    let duration = currTaskType?.Days;
    let isBusinessDays = currTaskType?.IsBusinessDays;
    let today = new Date();
    if(isBusinessDays){
      today = moment().businessAdd(duration)._d;
    }
    else{
      today.setDate(today.getDate() + duration);
    }
    let pEnd = duration !== 0 ? today : "";
    setFieldValue("taskDue", pEnd);
    this.setState({
        taskTypeDue: pEnd,
        taskDue: pEnd
    });
  }

  render() {
    if (this.context.officeAppType == null) {
      return <div className="mt-7 ml-2 mr-2">Office not detected.</div>;
    }
    if(this.context.currUser == null){
      this.getCurrUser();
    }
    if (this.context.officeAppType == "PowerPoint" || this.context.officeAppType == "Word") {
      if (!this.state.projectInfo) {
        let errorMessage = [];
        errorMessage.push("Please select a project before creating a task.");
        return (
          <div className="mt-7 ml-2 mr-2">
            <LoadingOverlay
              area="task-add-area"
              loading={this.state.taskAdd === null}
              inline="loading-overlay-inline"
              hideContent={this.hideContent}
            />
            <ValidationMessages valMsg={errorMessage}></ValidationMessages>
          </div>
        );
      }
    }

    return (
      <DynamicFieldsFormWrapper
        projectTypeId={0}
        taskTypeId={this.state.taskTypeId}
        objectType={OBJECT_TYPES.taskDetail}
        objectId={0}
        context={this.context}
      >
        {({ dynamicFields, setDynamicFields }) => (
          <div className="task-add-container">
            {this.context.officeAppType !== "Outlook" ?
              <LoadingOverlay area="task-add-area" hideContent={this.hideContent} />
              :
              <LoadingOverlay area="task-add-area" loading={this.state.loading} hideContent={this.hideContent} minDuration={100} />
            }
            {!this.state.hide && !this.state.showUseCase3 ? (
              <div className="mt-7">
                <div className="row mt-4 mb-3">
                  <div className="col-12">
                    <span className="projectPageTitle appFont ml-2">Create Task</span>
                  </div>
                </div>

                <Formik
                  validate={(values) => this.validate(values, dynamicFields, setDynamicFields)}
                  onSubmit={(values, { setSubmitting }) => {
                    this.addNewUsersToProject(this.state.projectInfo.Id);
                    this.isUseCase3(values, dynamicFields);
                    setSubmitting(false);
                  }}
                  initialValues={{
                    projectName: this.state.projectInfo?.Name || "",
                    taskName: this.state.taskName,
                    taskDue: this.state.taskDue ? this.state.taskDue: moment()
                    .add(7, "d")
                    .toDate(),
                    taskPriority: this.state.taskPriority,
                    taskType: this.state.taskTypeId,
                    taskStart: new Date(),
                    commentNote: this.state.commentNote,
                    taskFilter: "",
                    selectedMembers: [],
                    selectedSlides: ""
                  }}
                >
                  {({
                    setFieldValue,
                    setFieldTouched,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    validateForm,
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    isValidating,
                    isValid
                  }) => (
                    <Form onSubmit={handleSubmit} className="ml-2 mr-2">
                      {this.context.officeAppType === "Outlook" ? (
                        <Form.Group id="projName" >
                          <Form.Label className="loginInput appFontSubHeading">
                            {" "}
                            Project <i className="red">*</i>{" "}
                          </Form.Label>
                          <div className="input-group mb-3" style={{ paddingLeft: 0 }}>
                            <div className="input-group-append col-12" style={{ paddingLeft: 0 }}>
                              <div className="col-11" style={{ paddingLeft: 0 }}>
                                <Dropdown
                                  values={this.state.projectList.map(project => {
                                    return { id: project.Id, name: project.Name };
                                  })}
                                  dropdownButtonText={
                                    this.state.projectInfo?.Name?.length > 0 ?
                                      this.state.projectInfo?.Name
                                      :
                                      "Select a Project"}
                                  onSelect={selectedValue => {
                                    this.setState({ currentProjectId: selectedValue.id });
                                    this.setProjectId(selectedValue.id);
                                    setFieldValue("projectId", selectedValue.id);
                                    setFieldValue("projectName", selectedValue.name);
                                    setFieldTouched("projectName");
                                  }}
                                  //currentSelected={values.projectName}
                                  disabled={!this.state.projectList}
                                  isSearchable={true}
                                />
                                {values.projectName === "" && touched.projectName && (
                                  <div type="invalid" className="error-message">
                                    {errors.projectName}
                                  </div>
                                )}
                              </div>

                              <span
                                className="btn btn-outline-secondary shadow-none blueButtonInput"
                                onClick={() => this.props.setPage("projectsAdd")}
                              >
                                <img src={plusIcon} alt="Add Project"/>
                              </span>
                            </div>
                          </div>
                          <Form.Control.Feedback type="invalid" className="error-message">
                            {errors.projectName}
                          </Form.Control.Feedback>
                        </Form.Group>
                      ) : (
                        <Form.Group>
                          <Form.Label className="loginInput appFontSubHeading">
                            Project <i className="red">*</i>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            className="form-control form-custom-control shadow-none"
                            id="projectName"
                            name="projectName"
                            disabled="disabled"
                            placeholder="Enter project name"
                            value={values.projectName}
                          />
                        </Form.Group>
                      )}
                      {this.context.officeAppType == "PowerPoint" && (
                        <>
                          <Form.Group>
                            <Form.Label className="loginInput appFontSubHeading">
                              <img src={lockGrayIcon} alt="Locked Slides"/> Locked Slides
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className="form-control form-custom-control shadow-none"
                              id="lockedSlides"
                              name="lockedSlides"
                              disabled="disabled"
                              placeholder="-"
                              value={this.getUnavailableSlidesList(this.state.slidesUnavailable)}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label className="loginInput appFontSubHeading">
                              Select Slides <i className="red">*</i>
                            </Form.Label>
                            {
                              <>
                                <Form.Control
                                  type="text"
                                  className="form-control form-custom-control shadow-none"
                                  id="selectedSlides"
                                  name="selectedSlides"
                                  placeholder="Enter slide range, ie '1,3,5-12'"
                                  onChange={x => {
                                    this.updateSelectSlidesValue(x, setFieldTouched);
                                  }}
                                  isInvalid={
                                    (touched.selectedSlides && errors.selectedSlides) ||
                                    this.state.selectionErrors.length > 0
                                  }
                                  autoComplete="off"
                                  onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                />
                                <Form.Control.Feedback type="invalid" className="error-message">
                                  {errors.selectedSlides ?? this.state.selectionErrors}
                                </Form.Control.Feedback>
                              </>
                            }
                          </Form.Group>
                        </>
                      )}

                      <Form.Group className={touched.selectedSlides && errors.selectedSlides ? "mt-4" : ""}>
                        <Form.Label className="loginInput appFontSubHeading">
                          Task Name <i className="red">*</i>
                        </Form.Label>
                        <Form.Control
                          name="taskName"
                          id="taskName"
                          type="text"
                          className="form-control form-custom-control shadow-none"
                          value={values.taskName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.taskName && errors.taskName}
                          placeholder="Enter task name"
                          onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                        />
                        <Form.Control.Feedback type="invalid" className="error-message">
                          {errors.taskName}
                        </Form.Control.Feedback>
                      </Form.Group>

                      {this.context.officeAppType === "Outlook" &&
                        <Form.Group className={touched.taskName && errors.taskName ? "mt-4" : ""}>
                          <Form.Label className="loginInput appFontSubHeading">
                            Start Date <i className="red">*</i>
                          </Form.Label>
                          <DatePicker
                            className={`form-control form-custom-control shadow-none ${touched.taskStart && errors.taskStart ? "is-invalid" : ""
                              }`}
                            id="taskStart"
                            name="taskStart"
                            selected={values.taskStart}
                            onChange={value => {
                              value.setUTCHours(23, 59, 59, 0);
                              setFieldValue("taskStart", value);
                            }}
                            onBlur={() => setFieldTouched("taskStart")}
                            minDate={new Date()}
                            maxDate={
                              typeof values.taskDue !== "undefined" ? values.taskDue : new Date("December 31, 100 03:24:00")
                            }
                            placeholderText="MM/DD/YYYY"
                            autoComplete="off"
                            popperPlacement="top-start"
                          />
                          <img className="calendar-icon" src={calendarIcon} alt="Calendar Icon" />
                          {touched.taskStart && errors.taskStart && (
                            <div type="invalid" className="error-message">
                              {errors.taskStart}
                            </div>
                          )}
                        </Form.Group>
                      }

                      <Form.Group className={touched.taskName && errors.taskName ? "mt-4" : ""}>
                        <Form.Label className="loginInput appFontSubHeading">
                          Due Date <i className="red">*</i>
                        </Form.Label>
                        <DatePicker
                          className={`form-control form-custom-control shadow-none ${touched.taskDue && errors.taskDue ? "is-invalid" : ""
                            }`}
                          id="taskDue"
                          name="taskDue"
                          selected={this.state.endDateChange ? values.taskDue : this.state.taskTypeDue}
                          onChange={value => {
                            value.setUTCHours(23, 59, 59, 0);
                            setFieldValue("taskDue", value);
                            this.setState({endDateChange:true});
                          }}
                          onBlur={() => setFieldTouched("taskDue")}
                          minDate={new Date()}
                          maxDate={
                            typeof this.state.projectInfo !== "undefined" && this.state.projectInfo !== null
                              ? Date.parse(this.state.projectInfo.End)
                              : values.taskDue
                          }
                          placeholderText="MM/DD/YYYY"
                          autoComplete="off"
                        />
                        <img className="calendar-icon" src={calendarIcon} alt="Calendar Icon" />
                        {touched.taskDue && errors.taskDue && (
                          <div type="invalid" className="error-message">
                            {errors.taskDue}
                          </div>
                        )}
                      </Form.Group>
                      {this.context.officeAppType === "Outlook" &&
                        <Form.Group className={touched.taskDue && errors.taskDue ? "mt-4" : ""} id="taskType">
                          <Form.Label className="loginInput appFontSubHeading">
                            Task Type <i className="red">*</i>
                          </Form.Label>
                          <Dropdown
                            values={this.state.taskTypes.map(taskType => {
                              return { id: taskType.Id, name: taskType.Type };
                            })}
                            dropdownButtonText={"Select an Option"}
                            onSelect={selectedValue => {
                              const newWorkItemType = parseInt(selectedValue.id);
                              const currTaskType = this.state.taskTypes.find(type => type.Id === newWorkItemType);
                              setFieldValue("taskType", newWorkItemType);
                              setFieldTouched("taskType");
                              this.setState({ taskTypeId: newWorkItemType });
                              if(!this.state.endDateChange) this.setEndDate(setFieldValue, currTaskType);
                            }}
                            currentSelected={values.taskType}
                          />
                          {values.taskType === "" && touched.taskType && (
                            <div type="invalid" className="error-message">
                              {errors.taskType}
                            </div>
                          )}
                        </Form.Group>
                      }
                      <Form.Group className={touched.taskPriority && errors.taskPriority ? "mt-4" : ""} id="taskPriority">
                        <Form.Label className="loginInput appFontSubHeading">
                          Priority <i className="red">*</i>
                        </Form.Label>
                        <Dropdown
                          values={this.state.prioritiesAvailable.map(p => {
                            return { id: p, name: p };
                          })}
                          dropdownButtonText={"Select an Option"}
                          onSelect={selectedValue => {
                            setFieldValue("taskPriority", selectedValue.id);
                            setFieldTouched("taskPriority");
                          }}
                          currentSelected={values.taskPriority}
                        />
                        {values.taskPriority === "" && touched.taskPriority && (
                          <div type="invalid" className="error-message">
                            {errors.taskPriority}
                          </div>
                        )}
                      </Form.Group>

                      {renderTaskDetailDynamicFields({
                        dynamicFields: dynamicFields,
                        setDynamicFields,
                        disableErrors: this.state.disableDynamicDetailErrors
                      })}
                      <Form.Group
                        data-testid="selectedMembers"
                        className={touched.selectedMembers && errors.selectedMembers ? "mt-4" : ""}
                      >
                        <Form.Label className="loginInput appFontSubHeading">
                          Assign Members<i className="red">*</i>
                        </Form.Label>

                        <TaskAssignMembers
                          assignedMembers={this.state.assignedOtherDepMembers || this.state.assignedMembers}
                          assignedOwner={this.context.currUser}
                          filteredAssignedMembers={this.state.filteredOtherDepMembers || this.state.filteredAssignedMembers}
                          addMembersInForm={this.addMembersInForm}
                          removeMembersInForm={this.removeMembersInForm}
                        />
                        {touched.selectedMembers && errors.selectedMembers && (
                          <Form.Label>
                            <div type="invalid" className="error-message-assign">
                              {errors.selectedMembers}
                            </div>
                          </Form.Label>
                        )}
                      </Form.Group>
                      {!this.state.multipleEmailsSelected &&
                        <Form.Group className="mt-4 mb-1">
                          <Form.Label className="loginInput appFontSubHeading">Comments</Form.Label>
                          <Form.Control
                            className="form-control form-custom-control shadow-none projectDescription"
                            as="textarea"
                            id="commentNote"
                            name="commentNote"
                            rows="5"
                            maxLength={2000}
                            value={values.commentNote}
                            onChange={handleChange}
                            placeholder="Enter comment"
                          />
                        </Form.Group>
                      }
                      {!this.state.multipleEmailsSelected &&
                        <Form.Group>
                          <div className={"project-attachment" + (this.state.isError ? " invalid-border" : "")}>
                            <div className="image-upload w-10">
                              <div className="file-input-btn">
                                <label htmlFor="file-input" className="w-10">
                                  <img className="pointer" src={attachmentIcon} alt="Attachment" />
                                </label>
                              </div>
                              {this.state.selectedFiles.map(file => {
                                return (
                                  <div className="file-pill" key={file.name}>
                                    <div className="fileAttachment w-10">
                                      <OverlayTrigger
                                        placement="auto-start"
                                        delay={{ show: 250, hide: 50 }}
                                        overlay={
                                          <Tooltip id="tooltip-1" className="customTooltip" placement="auto-start">
                                            {file.name}
                                          </Tooltip>
                                        }
                                      >
                                        <span>{file.name}</span>
                                      </OverlayTrigger>
                                      <div className="w-1 float-right">
                                        <img
                                          src={removeAttachment}
                                          className="pointer float-right"
                                          alt="Remove Attachment"
                                          onClick={e => {
                                            this.deleteBrowseFile(e, file.name);
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                              <input
                                type="file"
                                id="file-input"
                                accept={validFileTypesString}
                                multiple
                                //onClick={e => {e.target.value=null}}
                                onChange={e => {
                                  this.browseFile(e);
                                }}
                              />
                            </div>
                          </div>
                        </Form.Group>
                      }
                      <span className="errorDisplayMessage" hidden={(this.state.isError) ? '' : 'hidden'}>{this.state.displayAlert}</span>

                      {this.context.officeAppType === "Outlook" && !this.props.isRegularTask ? (
                        <Form.Group>
                          <div className="row justify-content-start image-upload" style={{ marginLeft: "auto" }}>
                            <img src={emailIcon} alt="Email" />
                            <span className="p-2 appFontSubHeading">Email Thread</span>
                            {this.state.emailThread ? (
                              <span className="mr-auto fileAttachment w-7">
                                {this.state.emailThread.length > 30
                                  ? `${this.state.emailThread.slice(0, 30)}...`
                                  : this.state.emailThread}
                              </span>
                            ) : (this.state.multipleEmailsSelected && this.state.outlookEmails.map(email => (
                              email.emailThread ? (
                                <span key={email.emailId} className="mr-auto fileAttachment w-7 mt-1 email-thread">
                                  {email.emailThread}
                                </span>
                              ) : (
                                <React.Fragment key={email.emailId} />
                              )
                            )))}
                          </div>
                        </Form.Group>
                      ) : (
                        ""
                      )}

                      <div style={{ paddingBottom: "140px" }} />
                      <Form.Group>
                        <div className="fixed-bottom pt-4 pb-4 whiteBackground taskAddButtons">
                          <ValidationMessages valMsg={this.state.valMsgs}></ValidationMessages>
                          <div className="row">
                            <div className="col-12 taskModeButtonContainer">
                              <button
                                className="loginButton btn-primary blueButtonOutline shadow-none taskModeButton"
                                onClick={() => this.cancelTaskCreation()}
                              >
                                Cancel
                              </button>
                              <button
                                className="loginButton btn-primary blueButton shadow-none float-right taskModeButton taskAddSubmitBtn"
                                type="submit"
                                disabled={isSubmitting}
                                onClick={() => {
                                  this.setState({
                                    disableDynamicDetailErrors: false,
                                  })
                                }}
                              >
                                Create Task
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form.Group>
                      {/* Formik validation occurs automatically both on form change and on submit. Formik form events used to ensure the scroll event
                  is called only once on submit; during Formik validation when the form is registered as invalid.*/}
                      {isValidating && isSubmitting && !isValid ? this.scrollToError(touched, errors) : null}
                    </Form>
                  )}
                </Formik>

              </div>
            ) : null}
            {
              this.state.showUseCase3 &&
              <div className="readyForApproval">
                <div className="col-12 approvalSpan">
                  <div className="icon-warning pendingApproval"></div>
                  <span className="projectPageTitle appFont mt-5">Is the task ready for approval?</span>
                </div>
                <div className="fixed-bottom pt-4 pb-4 whiteBackground">
                  <div className="row">
                    <div className="col-12 taskModeButtonContainer">
                      <button
                        className="loginButton btn-primary blueButtonOutline shadow-none taskModeButton"
                        onClick={() => { this.onAddTask(this.state.values, 0, dynamicFields); this.setState({ showUseCase3: false }) }}
                      >
                        No
                      </button>
                      <button
                        className="loginButton btn-primary blueButton shadow-none float-right taskModeButton taskAddSubmitBtn"
                        onClick={() => { this.onAddTask(this.state.values, 1, dynamicFields); this.setState({ showUseCase3: false }) }}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        )}
      </DynamicFieldsFormWrapper>
    );
  }
}
export default TaskAdd;