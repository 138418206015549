import React, { useContext, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import SuccessIcon from "../../resources/success.png";
import AppContext from "../../app/AppContext";
import ApiService from "../../services/ApiService"
import TaskCreateButton from "../tasks/TaskCreateButton";

const ProjectsAdded = (props) =>  {
  const { setPage, setAppPage } = props;
  const context = useContext(AppContext);

  const projectList = () => setPage("projectsList");

  const createNewTask = async(emailTask) => {
    context.previousPage = "projectsAdded";
    if (context.officeAppType == "Outlook") {
      if(emailTask){
        await ApiService.getEmailTask(context.currEmail?.item?.conversationId, context)
        .then(async (result) => {
          if (result.status === 200) {
            const emailThreadExists = result.data;  // Comparing against conversationid
            context.previousPage = "projects";
            context.existingTask = emailThreadExists;
            if (!emailThreadExists) {
              setAppPage("task");
              setPage("task");
            } else {
              setPage("taskAddedFail");
            }
          } else {
            setPage("taskAddedFail");
          }
        });
      } else {
        context.previousPage = "projects";
        setAppPage("task : regularTask");
        setPage("task : regularTask");
      }
    //} else if (this.state.newDocument) {
    //  FileTransferService.uploadDocumentFile(Office.context, onProjectFileSlicesReady);
    } else {
      setPage("task");
    }
  };

  const openWebPage = () => window.open(context.applicationEndpoint + "projects");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  let activeView = null;
  activeView = (
    <div className="mt-7 mr-3">
      <div className="text-center">
        <img src={SuccessIcon} className="projectCreatedLogo"></img>
      </div>
      <div className="text-center mt-4">
        <span className="projectCreatedTitle">
          The project has been created
          <br />
          successfully.
        </span>
      </div>
      <div className="fixed-bottom ml-4 mr-4 pb-2 whiteBackground d-flex flex-column justify-content-end">
        <div className="d-flex justify-content-center align-items-end">
          {context.officeAppType === "Outlook" ? (
            <>
              <TaskCreateButton
                childButton={
                  <button className="loginButton btn-primary blueButtonOutline button50 shadow-none w-100">
                    Create Task
                  </button>
                }
                className="dropup"
                onClickEvent={createNewTask}
                isDropup={true}
              />
              <button
                className="loginButton btn-primary blueButton button50 shadow-none ml-2"
                onClick={() => projectList()}
              >
                Go to Project List
              </button>
            </>
          ) : (
            <div className="w-100">
              <button
                className="loginButton btn-primary blueButton shadow-none button100 w-100"
                onClick={() => projectList()}
              >
                Go to Project List
              </button>
            </div>
          )}
        </div>
        <div className="text-center mb-4 mt-3">
          <a href="#" onClick={() => openWebPage()}>
            <span className="appFont underline projectCreatedWebLink buttonLink">Switch to web application</span>
          </a>
        </div>
      </div>
    </div>
  )
  return <div>{activeView}</div>
}

export default ProjectsAdded;