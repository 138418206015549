import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import OfficeSharedService from "../services/OfficeSharedService";
import AppContext from "../app/AppContext";
import AppWord from "./word/AppWord";
import AppPowerPoint from "./powerpoint/AppPowerPoint";
import AppLegacy from "./AppLegacy";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../authConfigAzureAd";
import ApiService from "../services/ApiService";

const msalInstance = new PublicClientApplication(msalConfig);
const authType = window["APP_SETTINGS"].AUTH_TYPE;

const App = ({ isOfficeInitialized }) => {
  const context = useContext(AppContext);
  const [currentOfficeAppType, setCurrentOfficeAppType] = useState("");

  const authAzureAd = async () => {
    console.log("App.js Aquiring access token -->");
    await msalInstance.initialize();
    const accounts = await msalInstance.getAllAccounts();
    console.log("App.js accounts", accounts);
    if (accounts.length > 0) {
      console.log("App.js accounts.length > 0");
      const request = {
        ...loginRequest,
        account: accounts[0]
      };
      msalInstance
        .acquireTokenSilent(request)
        .then(response => {
          console.log("App.js authAzureAd Access token -->");
          console.log(response);
          this.setAuthCookie(response.accessToken);
        })
        .catch(error => {
          if (error.errorMessage.includes("interaction_required")) {
            msalInstance.acquireTokenRedirect(request);
          } else {
            console.log("App.js authAzureAd Access token error -->");
            console.error(error);
          }
        });
    }
  };

  useEffect(() => {
    console.log("[0] App.js - useEffect => Started");
    const OfficeSharedServiceResult = OfficeSharedService.getAppType(Office.context);
    if (OfficeSharedServiceResult !== "") {
      console.log("[0.1] App.js - useEffect => [OfficeSharedServiceResult] completed: ", OfficeSharedServiceResult);
      context.officeAppType = OfficeSharedServiceResult;
      setCurrentOfficeAppType(OfficeSharedServiceResult);
      if (OfficeSharedServiceResult == "Word") context.officeAppTypeDoc = 1;
      else if (OfficeSharedServiceResult == "PowerPoint") context.officeAppTypeDoc = 3;
      else if (OfficeSharedServiceResult == "Outlook") context.officeAppTypeDoc = 4;
    }
    if (authType === "AzureAD") {
      console.log("App.js Auth Type AzureAD");
      let userToken = localStorage.getItem("tmpTriyoToken");
      if (userToken) {
        console.log("App.js this.context.userToken Value -->");
        const decodedToken = JSON.parse(atob(userToken.split(".")[1]));
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp > currentTime) {
          console.log("App.js Token Not Expired -->");
          context.userToken = userToken;
          context.jwtUserToken = userToken;
          ApiService.getCurrentUser(context)
            .then(result => {
              console.log(result);
              if (result.status === 200) {
                if (result.data.User.Active === false) {
                  this.setState({ valMsgs: "This User Account is no longer Active. Contact Admin." });
                  return;
                }
                context.currUser = result.data.User;
                context.userName = result.data.User.UserName;
                context.userNameFull = result.data.User.Name;
                context.userEmail = result.data.User.Email;
                context.userLogin = true;
                context.tokenReady = true;
                console.log("this.context.tokenReady", context.tokenReady);
                this.props.onLoginChange(true);
              } else {
                console.log("API [ApiDataService.login] error status: " + result.status);
              }
            })
            .catch(error => {
              this.setState({ valMsgs: "Invalid username or password" });
              console.log("API [ApiDataService.login] error ->");
              console.log(error);
            });
        } else {
          console.log("App.js Token Expired -->");
          authAzureAd();
        }
      } else {
        console.log("App.js No this.context.userToken Value calling authAzureAd -->");
        authAzureAd();
      }
    } else {
      // Other auth types
    }
  }, []);

  useEffect(() => {
    console.log("[0.2] App.js - useEffect => State changes detected...");
  }, [currentOfficeAppType]);

  return (
    <div>
      {currentOfficeAppType == "Word" && <AppWord />}
      {currentOfficeAppType == "PowerPoint" && <AppPowerPoint isOfficeInitialized={isOfficeInitialized} />}
      {currentOfficeAppType == "Outlook" && <AppLegacy />}
    </div>
  );
};

App.propTypes = {
  isOfficeInitialized: PropTypes.bool.isRequired
};

export default App;

// Components to be decoupled and unit-tested later:
// - AppOutlookDesktop.js
// - AppOutlookPWA.js
//
// Code:
// import AppOutlookDesktop from "./outlook/AppOutlookDesktop";
// import AppOutlookPWA from "./outlook/AppOutlookPWA";
//
// { currentOfficeAppType == "Outlook" && <AppOutlookDesktop /> }
// { currentOfficeAppType == "OutlookPWA" && <AppOutlookPWA /> }
// { currentOfficeAppType == "" && <AppLegacy /> }
//
// Note: AppLegacy, AppOutlookPWA, and AppOutlook are exact copy of the
// original App.js and remain unchanged
