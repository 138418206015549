import React, { useState, useEffect, useContext} from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import { useAutoRefreshForAddin } from "../dynamicFields/utils/utils";
import "bootstrap/dist/css/bootstrap.min.css";
import Moment from "react-moment";
import TaskMembersPopover from "../tasks/TaskMembersPopover";
import AppContext from "../../app/AppContext";
import ApiService from "../../services/ApiService";
import SecurityMatrixService from "../../services/SecurityMatrixService";
import OfficeWordService from "../../services/OfficeWordService";
import OfficeSharedService from "../../services/OfficeSharedService";
import FileTransferService from "../../services/FileTransferService";
import LoadingOverlay from "../loading-overlay/loading-overlay";
import Dropdown from "../dropdown/dropdown";
import infoTooltip from "../../resources/Icons/Icon - Info Tooltip.svg";
import TaskCreateButton from "../tasks/TaskCreateButton";
import TasksDynamicFieldsContent from "../dynamicFields/ContentViewer/TasksDynamicFieldsContent";
import ManualRefreshButton from "../manual-refresh-button/manual-refresh-button";


const ProjectDetails = ({ projectInfo, setAppPage, setPage, setCurrentProjectAddTask }) => {
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [currentProject, setCurrentProject] = useState(projectInfo);
  const [newDocument, setNewDocument] = useState(false);
  const [currentFileName, setCurrentFileName] = useState("");
  const [documents, setDocuments] = useState([]);
  const [hide, setHide] = useState(false);
  const [isOverdue, setIsOverdue] = useState(false);
  const [isNotAllowed, setIsNotAllowed] = useState(true);
  const documentType = {
    word: 1,
    powerpoint: 3
  };


  const context = useContext(AppContext);

  const findProjectDocumentFromProps = fileNameId => {
    return currentProject?.Documents?.find(document => document.FileNameId === fileNameId);
  };

  const displayErrorIfOverdue = () => {
    // due date check and display alert error message
    let projectEnd = new Date(currentProject.End);
    let today = new Date();
    if (projectEnd.getTime() <= today.getTime()) {
      setIsOverdue(true);
    }
  };

  const displayErrorIfNotAllowed = () => {
    // Check if member is allowed to create Tasks and display alert error if needed
    let isNotMember = true;
    currentProject.Members.forEach(member => {
      if (member.UserId === context.currUser.Id) isNotMember = false;
    });

    setIsNotAllowed(isNotMember);
  };

  const getProjectDocument = id => {
    trackPromise(
      ApiService.getProjectDocument(id, context)
        .then(async result => {
          if (result.status === 200) {
            const projectDocument = findProjectDocumentFromProps(id);

            if (context.officeAppType === "PowerPoint") {
              switch (projectDocument.DocumentTypeId) {
                case documentType.powerpoint:
                  PowerPoint.createPresentation(result.data);
                  break;

                case documentType.word:
                  const blob = await (
                    await fetch(
                      `data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${result.data}`
                    )
                  ).blob();
                  saveAs(blob, projectDocument.Name);
                  break;
                default:
                // undefined
              }
            } else if (context.officeAppType === "Word") {
              switch (projectDocument.DocumentTypeId) {
                case documentType.word:
                  OfficeWordService.createDocument(result.data);
                  break;
                case documentType.powerpoint:
                  const blob = await (
                    await fetch(
                      `data:application/vnd.openxmlformats-officedocument.presentationml.presentation;base64,${result.data}`
                    )
                  ).blob();
                  saveAs(blob, projectDocument.Name);
                  break;
                default:
                // undefined
              }
            }
          } else {
            console.log("API [GetProjectDocument] error status: " + result.status);
          }
        })
        .catch(e => {
          console.log("API [GetProjectDocument] error ->");
          console.log(e);
          setAppPage("500 error");
        }),
      "project-details-area"
    );
  };

  const hideContent = hidden => {
    if (hide != hidden) {
      setHide(hidden);
    }
  };

  const checkNewDocument = () => {
    const currentDocumentID = context.currentProjectDocumentId;
    let duplicateDocument = currentProject?.Documents?.find(document => document.Id == currentDocumentID);
    if (!duplicateDocument) {
      setNewDocument(true);
    }
  };

  const generateDocumentList = () => {
    const currentDocumentID = context.currentProjectDocumentId;

    console.log("Documents ->");
    console.log(currentProject.Documents);

    let vals = currentProject.Documents;

    if (!newDocument) {
      vals = vals.filter(document => document.Id != currentDocumentID);
    }

    vals = vals.map(m => {
      return { id: m.FileNameId, name: m.Name };
    });
    vals.unshift({ id: 0, name: `${currentFileName} (Current Document)` });

    setDocuments(vals);
  };

  const getUpdatedProjects = async () => {
    await ApiService.getUserProjects(context)
      .then(result => {
        if (result.status === 200) {
          let currProjects = result.data?.filter(p => p.Id == projectInfo?.Id)[0];
          setCurrentProject(currProjects);
        } else {
          console.log("API [GetUserProject] error status: " + result.status);
        }
      })
      .catch(e => {
        console.log(e);
      });
  };

  const generateDocumentListNoCurrent = () => {
    console.log("Documents No Current ->", currentProject.Documents);
    let vals = currentProject.Documents.map(m => {
      return { id: m.FileNameId, name: m.Name };
    });
    console.log("Documents No Current List ->");
    console.log(vals);
    setDocuments(vals);
  };

  const getFileName = () => {
    console.log("Start File Name - >");
    OfficeSharedService.getFileName(Office.context, fileName => {
      if (fileName == "") generateDocumentListNoCurrent();
      let data = {
        FileName: fileName,
        DocumentTypeId: 1
      };
      ApiService.getFileName(data, context)
        .then(result => {
          if (result.status === 200) {
            console.log("File Name - >");
            console.log(result.data);
            setCurrentFileName(result.data);
            generateDocumentList();
          } else {
            console.log("API [GetFileName] error status: " + result.status);
          }
        })
        .catch(e => {
          console.log("API [GetFileName] error ->");
          console.log(e);
          setAppPage("500 error");
        });
    });
  };

  const createNewTask = emailTask => {
    if (context.officeAppType == "Outlook") {
      if (!emailTask) {
        context.previousPage = "projects";
        setAppPage("task : regularTask");
        setPage("task : regularTask");
        return;
      }

      ApiService.getEmailTask(context.currEmail?.item?.conversationId, context).then(async result => {
        if (result.status === 200) {
          const emailThreadExists = result.data; // Comparing against conversationid
          context.previousPage = "projects";
          context.existingTask = emailThreadExists;
          if (!emailThreadExists) {
            setAppPage("task");
            setPage("task");
          } else {
            setPage("taskAddedFail");
          }
        } else {
          setPage("taskAddedFail");
        }
      });
    } else if (newDocument) {
      //trackPromise(
      FileTransferService.uploadDocumentFile(Office.context, onProjectFileSlicesReady);
      //);
    } else {
      setCurrentProjectAddTask();
    }
  };

  const onProjectFileSlicesReady = slices => {
    if (Office.context.document != null) {
      Office.context.document.getFilePropertiesAsync(function(asyncResultProperties) {
        let fileName = "";
        if (asyncResultProperties.value.url != "") {
          fileName = asyncResultProperties.value.url;
        }

        uploadProjectDocument(slices, fileName);
      });
    }
  };

  const uploadProjectDocument = (docdataSlices, fileName) => {
    let file = FileTransferService.getFileFromSlices(docdataSlices, context.officeAppType);
    const fd = new FormData();
    fd.append("file", file);
    fd.append("projectId", currentProject.Id);
    fd.append("fileType", context.officeAppTypeDoc);
    fd.append("fileName", fileName);

    trackPromise(
      (async () => {
        try {
          const addProjectFileResult = await ApiService.addProjectFile(fd, context);

          if (addProjectFileResult.status !== 200) {
            console.log("API [AddProjectFile] error status: " + addProjectFileResult.status);
            return;
          }

          const getProjectDocumentResult = await ApiService.getProjectDocument(
            addProjectFileResult.data.FileNameId,
            context
          );

          if (getProjectDocumentResult.status !== 200) {
            console.log("API [GetProjectDocument] error status: " + getProjectDocumentResult.status);
            return;
          }

          switch (context.officeAppType) {
            case "PowerPoint":
              PowerPoint.createPresentation(getProjectDocumentResult.data);
              break;
            case "Word":
              OfficeWordService.createDocument(getProjectDocumentResult.data);
              break;
            default:
            // undefined
          }
          context.closeInstance = true;
          context.previousPage = "taskCreate";
          setPage("projectsAdded");
        } catch (uploadProjectDocumentError) {
          console.log("API [AddProjectFile / GetProjectDocument] error ->");
          console.log(uploadProjectDocumentError);
          setAppPage("500 error");
        }
      })(),
      "project-details-area"
    );
  };

  const getProjectDetailsData = async () => {
    checkNewDocument();
    getFileName();
    displayErrorIfOverdue();
    displayErrorIfNotAllowed();
    window.scrollTo(0, 0);
  };

  // Auto render after some period of time - Project Details
  // useAutoRefreshForAddin(getUpdatedProjects, 15000);
  // First time render
  useEffect(() => {
    getProjectDetailsData();
  }, [currentProject]);

  const showEmailMenuOptions = () => {
    let emailMenuDisplay = "";
    if (isOverdue || isNotAllowed) {
      emailMenuDisplay = (
        <button className="col-12 loginButton disabledBlueButton shadow-none mb-3 w-100 btn-secondary" disabled hidden = {context.DisableActions}>
          Create New Task
        </button>
      );
    } else if (context.officeAppType === "Outlook") {
      emailMenuDisplay = (
        <TaskCreateButton
          childButton={
            <button className="col-12 loginButton blueButton shadow-none mb-3 w-100 btn-primary" hidden = {context.DisableActions}>
              Create New Task
            </button>
          }
          isWide={true}
          className="dropup"
          onClickEvent={createNewTask}
          isDropup={true}
        />
      );
    } else {
      emailMenuDisplay = (
        <button className="col-12 loginButton blueButton shadow-none mb-3 w-100 btn-primary" onClick={createNewTask} hidden = {context.DisableActions}>
          Create New Task
        </button>
      );
    }

    return emailMenuDisplay;
  };

  const showProjectDetails = () => {
    let activeView = null;
    activeView = (
      <div>
        <LoadingOverlay
          area="project-details-area"
          inline="loading-overlay-inline show-back-btn"
          hideContent={hideContent}
        />
        <div className="mt-7">
          <div className="row projectToolbarSticky col-12 ">
            <div className="col-12 mb-4 pt-4 pl-0">
              <span className="d-flex justify-content-between mr-1">
                <span className="appFont taskGoItems tasksGoBack" onClick={() => setPage("projects")}>
                  Back
                </span>
                <ManualRefreshButton refreshService={getUpdatedProjects}/>
              </span>
            </div>
            <div className="row mr-1 mb-4 pl-4">
              <div className="projectPageTitle bold ">Project Details</div>
            </div>
          </div>
          {!hide && (
            <div className="scrollProjectDetails mb-4">
              {isOverdue && (
                <div className="alert alert-warning col-12" role="alert">
                  This Project is Overdue. Cannot create new Tasks.
                </div>
              )}
              <div className="row ml-2 mr-1">
                <div className="col-12 taskDetailsRow appFont">
                  <div className="float-left taskDetailsLabel">PROJECT NAME</div>
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 250 }}
                    overlay={<Tooltip id="tooltip-1">{currentProject.Name}</Tooltip>}
                  >
                    <div className="float-right text-right mr-2 taskDetailsText taskDescription">
                      {currentProject.Name}
                    </div>
                  </OverlayTrigger>
                </div>
              </div>
              <div className="row ml-2 mr-1">
                <div className="col-12 taskDetailsRow appFont">
                  <div className="float-left taskDetailsLabel">PROJECT TEMPLATE</div>
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 250 }}
                    overlay={<Tooltip id="tooltip-1">{currentProject.ParentTemplateName}</Tooltip>}
                  >
                    <div className="float-right text-right mr-2 taskDetailsText taskDescription">
                      {currentProject.ParentTemplateName}
                    </div>
                  </OverlayTrigger>
                </div>
              </div>
              <div className="row ml-2 mr-1">
                <div className="col-12 taskDetailsRow appFont">
                  <div className="float-left taskDetailsLabel">START DATE</div>
                  <div className="float-right text-right mr-2 taskDetailsText">
                    <Moment format="MM/DD/YYYY">{currentProject.Start}</Moment>
                  </div>
                </div>
              </div>
              <div className="row ml-2 mr-1">
                <div className="col-12 taskDetailsRow appFont">
                  <div className="float-left taskDetailsLabel">DUE DATE</div>
                  <div className="float-right text-right mr-2 taskDetailsText">
                    <Moment format="MM/DD/YYYY">{currentProject.End}</Moment>
                  </div>
                </div>
              </div>
              <div className="row ml-2 mr-1">
                <div className="col-12 taskDetailsRow appFont">
                  <div className="float-left taskDetailsLabel">PRIORITY</div>
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 250 }}
                    overlay={<Tooltip id="tooltip-1">{currentProject.Priority}</Tooltip>}
                  >
                    <div className="float-right text-right mr-2 taskDetailsText taskDescription">
                      {currentProject.Priority}
                    </div>
                  </OverlayTrigger>
                </div>
              </div>
              <div className="row ml-2 mr-1">
                <div className="col-12 taskDetailsRow appFont">
                  <div className="float-left taskDetailsLabel mt-1">MEMBERS</div>
                  <div className="float-right text-right mr-2 taskDetailsText">
                    <TaskMembersPopover
                      isProject={true}
                      projMembers={currentProject.Members}
                      ownerId={currentProject.CreatedById}
                    />
                  </div>
                </div>
              </div>
              <div className="row ml-2 mr-1">
                <div className="col-12 taskDetailsRow appFont">
                  <div className="float-left taskDetailsLabel">DESCRIPTION</div>
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 250 }}
                    overlay={<Tooltip id="tooltip-1">{currentProject.Description}</Tooltip>}
                  >
                    <div className="float-right text-right mr-2 taskDetailsText taskDescription">
                      {currentProject.Description}
                    </div>
                  </OverlayTrigger>
                </div>
              </div>
              <div className="row ml-2 mr-1">
                <div className="col-12 taskDetailsRow appFont">
                  <div className="float-left taskDetailsLabel">TASKS</div>
                  <div className="float-right text-right mr-2 taskDetailsText">
                    {currentProject.TasksDocumentCount + currentProject.TasksRegularCount}{" "}
                  </div>
                </div>
              </div>
              <TasksDynamicFieldsContent
                projectTypeId={currentProject.ParentTemplateId}
                task={context.currentTask}
                taskTypeId={0}
                objectId={currentProject.Id}
                objectType={"Project"}
                sidePanelStyles={true}
              />
              {SecurityMatrixService.checkRolePrivilege(context.currUser, "Add", "Task Management") && (
                <div className="createTaskSection">
                  <div className="row ml-2 mr-1 mb-4">
                    {context.officeAppType === "PowerPoint" || context.officeAppType === "Word" ? (
                      <div className="projectPageTitle bold mt-1">Create Project Tasks</div>
                    ) : (
                      ""
                    )}
                  </div>

                  {context.officeAppType === "PowerPoint" || context.officeAppType === "Word" ? (
                    <Dropdown
                      values={documents}
                      dropdownButtonText="Select a project document"
                      onSelect={selected => {
                        if (selected.id > 0) {
                          context.appMode = "taskCreate";
                          getProjectDocument(selected.id);
                        } else {
                          setSelectedDocument(selected.id);
                        }
                      }}
                      selected={selectedDocument}
                    />
                  ) : (
                    ""
                  )}
                  {selectedDocument === 0 && newDocument && (
                    <div className="mt-1">
                      <img src={infoTooltip} className="infoTooltip" alt="Info Tooltip" />
                      <span className="newDocumentText">
                        {" "}
                        This document has not been added to any tasks under this project.{" "}
                      </span>
                    </div>
                  )}
                  {(selectedDocument === 0 || context.officeAppType === "Outlook") && (
                    <div className="fixed-bottom pl-3 pr-3 pr-4 pb-4 whiteBackground">{showEmailMenuOptions()}</div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
    return activeView;
  };

  return <div>{showProjectDetails()}</div>;
};

export default ProjectDetails;
