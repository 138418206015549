import { Configuration } from "@azure/msal-browser";

const clientId = window["APP_SETTINGS"].REACT_APP_AUTH_CLIENT_ID;
const authority = window["APP_SETTINGS"].REACT_APP_AUTH_AUTHORITY;
const redirectUri = window["APP_SETTINGS"].REACT_APP_AUTH_REDIRECT_URI;

export const msalConfig = {
  auth: {
    clientId: clientId,
    authority: authority,
    redirectUri: redirectUri
  }
};

export const loginRequest = {
  scopes: ["User.Read"]
};
