class OfficeSharedService {
  getAppType(officeContext) {
    if (officeContext != null && officeContext.diagnostics != null) {
      if (officeContext.diagnostics.host != null && officeContext.diagnostics.host == "PowerPoint") {
        console.log("Office -> PowerPoint");
        return "PowerPoint";
      } else if (officeContext.diagnostics.host != null && officeContext.diagnostics.host == "Excel") {
        console.log("Office -> Excel");
        return "Excel";
      } else if (officeContext.diagnostics.host != null && officeContext.diagnostics.host == "Word") {
        console.log("Office -> Word");
        return "Word";
      } else if (officeContext.diagnostics.host != null && officeContext.diagnostics.host == "Outlook") {
        console.log("Office -> Outlook");
        return "Outlook";
      } else {
        console.log("Office -> Not Found");
        return "";
      }
    }
  }

  getFileName(officeContext, callbackFunction) {
    if (officeContext?.document != null) {
      officeContext.document.getFilePropertiesAsync(function(asyncResultProperties) {
        console.log(asyncResultProperties);
        if (asyncResultProperties.value.url != "") {
          return callbackFunction(asyncResultProperties.value.url);
        }
        return callbackFunction("");
      });
    }
  }

  getCode(length) {
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
}

export default new OfficeSharedService();
